/* eslint-disable */
import React from 'react';
import styled from 'styled-components';
import { Table } from 'react-bootstrap';
import AsyncSelect from 'react-select/async';
import { LookupItem } from '../../api/lookups/ResponseTypes';
import { debounce } from 'ts-debounce';

import FullScreenLoader from '../ui-components/FullScreenLoader';
import LookupsApi from '../../api/lookups/Lookups';
import Analytics from '../../services/analytics.service';
import Utilities from '../../Utilities';
import theme from '../../css/theme';
import InternalTracker from 'src/InternalTracker';
import SimpleTooltip from '../ui-components/SimpleTooltip';

interface Props {
    industries: LookupItem[];
    sectorId?: number;
    sectorName?: string;
    onSelected: (industry: LookupItem) => void;
    onRemoved: (id: number) => void;
    searchPlacement?: 'top' | 'bottom' | 'auto';
    enabled: boolean;
    enableSuggestion?: boolean;
    minimal?: boolean;
    hideInputAfterOne?: boolean;
    autoFocus?: boolean;
}

interface State {
    value: SelectItem | null;
    justAddedId: number | null;
    loadingMessage: string | null;
    minimalOverriden: boolean;
    mostCommon: LookupItem[];
    hideInputAfterOneOverriden?: boolean;
}

interface SelectItem {
    label: string;
    value: any;
}

export default class IndustrySelector extends React.Component<Props, State> {
    state = {
        value: null,
        justAddedId: null,
        loadingMessage: null,
        minimalOverriden: false,
        mostCommon: [],
        hideInputAfterOneOverriden: false
    } as State;

    componentDidMount(): void {
        this.getMostCommon();
    }

    handleChange = (item, reason) => {
        if (item.label.startsWith('Suggest \'')) {
            if (item.label.endsWith(" subsector")) {
                this.suggest("subsector", item.value, this.props.sectorId)
            } else {
                this.suggest("sector", item.value);
            }
        } else if (reason.action == 'select-option') {
            if (!this.props.industries.some((i) => i.id == item.value)) {
                this.props.onSelected({ id: item.value, value: item.label });

                // Clear selection
                this.setState({
                    value: null,
                    justAddedId: item.value
                });

                InternalTracker.trackEvent((this.props.sectorId ? "Subsectors" : "Industries") + " Selected", {
                    id: item.value,
                    industryId: this.props.sectorId,
                })

                setTimeout(() => {
                    this.setState({
                        justAddedId: null
                    });
                }, 800);

                Analytics.trackEvent('industry-selector-industry-selected', {
                    industry: item.value
                });
            }
        }
    };

    handleRemove = (id: number) => {
        this.props.onRemoved(id);

        Analytics.trackEvent('industry-selector-industry-removed', {
            industry: id
        });
    };

    getMostCommon = async () => {
        const mostCommon = this.props.sectorId ? (await LookupsApi.subSectors("", this.props.sectorId)) : (await LookupsApi.industries(""));
        if (mostCommon && mostCommon.data) {
            this.setState({
                mostCommon: mostCommon.data
            })
        }
    }

    // Only query after 500ms of inactivity
    query = debounce((val: string, callback) => {
        InternalTracker.trackEvent((this.props.sectorId ? "Industries" : "Subsectors") + " Search", {
            keywords: val
        })
        if (this.props.sectorId) {
            LookupsApi.subSectors(val, this.props.sectorId).then((industries) => {
                let items = industries.data ? industries.data.map((i) => {
                    return {
                        value: i.id,
                        label: i.value
                    } as SelectItem;
                }) : [];
                if (items.length === 0) {
                    items.push({
                        value: val,
                        label: "Suggest '" + val + "' subsector"
                    })
                }
                // Pass back to react-select
                callback(items);
                Analytics.trackEvent('subsector-selector-search', {
                    searchTerm: val,
                    resultsFound: items.length
                });
            });
        } else {
            LookupsApi.industries(val).then((industries) => {
                let items = industries.data ? industries.data.map((i) => {
                    return {
                        value: i.id,
                        label: i.value
                    } as SelectItem;
                }) : [];
                if (items.length === 0) {
                    items.push({
                        value: val,
                        label: "Suggest '" + val + "' sector"
                    })
                }
                // Pass back to react-select
                callback(items);
                Analytics.trackEvent('industry-selector-search', {
                    searchTerm: val,
                    resultsFound: items.length
                });
            });
        }
    }, 500);
    
    suggest = async (type: "sector" | "subsector", value: string, sectorId?: number) => {
        if (type === "subsector") {
            this.setState({
                loadingMessage: 'Creating Suggestions'
            });
            InternalTracker.trackEvent("Suggested Subsector", { 
                subsector: value,
                sectorId: sectorId
            })
            let newId = await LookupsApi.suggestSubSector(
                Utilities.capitalizeFirstLetter(
                    value
                ),
                sectorId || 0
            );
            if (newId) {
                this.props.onSelected({
                    // @ts-ignore
                    id: parseInt(newId),
                    value: value
                });
            } else {
                alert('Failed to create suggestions');
            }
            this.setState({ loadingMessage: null });
        } else {
            this.setState({
                loadingMessage: 'Creating Suggestions'
            });
            InternalTracker.trackEvent("Suggested Subsector", { 
                sector: value,
            })
            let newId = await LookupsApi.suggestSector(
                Utilities.capitalizeFirstLetter(
                    value
                )
            );
            if (newId) {
                this.props.onSelected({
                    // @ts-ignore
                    id: parseInt(newId),
                    value: value
                });
            } else {
                alert('Failed to create suggestions');
            }
            this.setState({ loadingMessage: null });
        }
    }

    render() {
        const env = window.location.host === "portal.updatedge.com" ? "prod" : "dev";
        
        const mainCategories = [
          { value: "Education", id: env === "prod" ? 1076 : 227, img: "education", color: "#70BF96" },
          { value: "Social Care", id: env === "prod" ? 1095 : 980, img: "socialcare", color: "#79A2EF" },
          { value: "Hospitality", id:  env === "prod" ? 1092 : 970, img: "hospitality", color: "#AA77F0" },
          { value: "Something Else", id: 0, img: "more", color: "#6A6A6A" }
        ]

        return (
            <SectorContent>
                { (this.props && this.props.minimal && !this.state.minimalOverriden) ?
                    <div className='minimal'>
                        { mainCategories.map(sector => {
                            return (
                                <div 
                                    style={{ background: sector.color }}
                                    onClick={ async () => {
                                        if (sector.id === 0) {
                                            this.setState({
                                                minimalOverriden: true
                                            })
                                        } else {
                                            this.handleChange({
                                                label: sector.value,
                                                value: sector.id
                                            }, {
                                                action: 'select-option'
                                            })
                                        }
                                    }}
                                >
                                    { (sector.img) &&
                                        <img src={'/img/sectors/' + sector.img + ".png"} />
                                    }
                                    <p>{sector.value}</p>
                                </div>
                            )
                        }) }
                    </div>
                    :
                    <React.Fragment>
                        { (this.props.hideInputAfterOne && !this.state.hideInputAfterOneOverriden) ?
                            null
                            : 
                            <AsyncSelect
                                inputId={"industry-select-" + this.props.sectorId}
                                loadOptions={this.query}
                                defaultOptions={this.state.mostCommon.map((i) => {
                                    return {
                                        value: i.id,
                                        label: i.value
                                    } as SelectItem;
                                }) }
                                menuPlacement={this.props.searchPlacement || 'bottom'}
                                onChange={this.handleChange}
                                value={this.state.value}
                                placeholder={
                                    this.props.sectorName
                                        ? 'Select subsectors for ' +
                                            this.props.sectorName
                                        : "Select a sector... (e.g. 'Education')"
                                }
                                isDisabled={!this.props.enabled}
                                noOptionsMessage={(opts) =>
                                    opts.inputValue
                                        ? 'No options found'
                                        : 'Search for a sector...'
                                }
                                autoFocus={this.props.autoFocus}
                            />
                        }
                        <div 
                            className='tags'
                            style={ (this.props.hideInputAfterOne && !this.state.hideInputAfterOneOverriden) ? { textAlign: 'left' } : { } }
                        >
                            {this.renderSelected()}
                            { (this.props.hideInputAfterOne && !this.state.hideInputAfterOneOverriden) ?
                                <span style={{ 
                                    backgroundColor: theme.colours.updatedge,
                                    position: 'relative',
                                }} className='tag add-another-industry' onClick={() => this.setState({ hideInputAfterOneOverriden: true })}>
                                    <i className="fa fa-plus" />
                                    <SimpleTooltip
                                        alwaysShow={true}
                                        id="add-another-industry-tooltip"
                                        hide={false}
                                        placement="top"
                                        text="You can add more sectors after selecting your subsectors"
                                    >
                                        
                                    </SimpleTooltip>
                                </span>
                                : null
                            }
                        </div>
                        <FullScreenLoader
                            loadingMessage={this.state.loadingMessage}
                        />
                    </React.Fragment>
                }
            </SectorContent>
        );
    }

    renderSelected() {
        return (this.props.industries || []).map((i) => (
            <span className='tag' key={i.id} data-just-added={this.state.justAddedId === i.id ? "true" : "false"}>
                {i.value || i.name}
                {this.props.enabled && (
                    <i
                        style={{ marginLeft: 6 }}
                        className="fa fa-trash-o fa-hover"
                        onClick={() => this.handleRemove(i.id)}
                        aria-label="Remove Industry"
                    />
                )}
            </span>
        ));
    }
}

const SectorContent = styled.div`
    .minimal {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        max-width: 500px;

        &>div {
            flex-basis: calc(33% - 9px);
            border-radius: 12px;
            margin-bottom: 15px;
            overflow: hidden;
            margin-right: 15px;
            cursor: pointer;
            
            img {
                display: block;
            }

            p {
                margin: 8px 0 10px 0;
                font-weight: 700;
                color: white;
            }

            &:nth-child(3), &:nth-child(4) {
                margin-right: 0;
            }

            &:nth-child(4) {
                flex-basis: 100%;
                display: flex;
                padding: 0px 10px;
                align-items: center;
                justify-content: center;
                margin-bottom: 5px;

                img {
                    width: 64px;
                }
            }
        }
    }

    table {
        margin-top: 8px;
    }

    .tags {
        margin: 8px 0;

        .tag {
            margin-right: 8px;
            margin-bottom: 8px;

            &[data-just-added='true'] {
                background-color: ${theme.colours.amber}
            }
        }
    }

    .fa-trash-o {
        font-size: 18px;
    }

    td:last-child {
        width: 40px;
    }

    td {
        border-bottom: 1px solid #ddd;
        border-top: none !important;
        padding: 8px 5px;
    }

    table {
        margin-bottom: 5px;
    }

    tr {
        background-color: white;
        transform: all 0.3s;
    }

    tr[data-just-added='true'] {
        background-color: #f9f982;
    }

    button {
        border: none;
        background-color: ${theme.colours.darkBlue};
        color: white;
        border-radius: 3px;
        padding: 4px 10px;
    }

    #add-another-industry-tooltip {
        width: 206px;
        margin-left: 52px;
        margin-top: -5px;
    }
`;
