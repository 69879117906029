/* eslint-disable */
import React from 'react';
import styled from 'styled-components';
// import { Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { History } from 'history';

import Chat from '../../components/Chat/Conversations';
import { ModalProgressGuide } from '../profile-progress/ModalProgressGuide';
import DashboardContainer from '../../state-containers/dashboard.container';
import { UserTypeContainer } from '../../state-containers/user-type.container';
import { Popovers } from '../profile-progress/Tasks';
import { ApplicationState } from '../../store';
import * as ProfileState from '../../store/Profile';
import * as EventsState from '../../store/Events';
import { Subscribe } from '../../unstated-fork/unstated';
import SimpleTooltip from '../../components/ui-components/SimpleTooltip';
import SettingsAPI from '../../api/settings/Settings';
import { Setting } from '../../api/settings/ResponseTypes';
import theme from 'src/css/theme';
import ProfileApi from '../../api/profile/Profile';

export enum IconType {
    FontAwesome,
    Dynamic
}

export interface Icon {
    path: string;
    type: IconType;
}

export interface Item {
    icon: Icon;
    notificationNumber: number | null;
    tooltipText: string;
    id?: string;
}

interface State {
    key: number;
    showProgress: boolean;
    showChat: boolean;
    items: { [key: string]: Item };
    selectedItem: number | null;
    prevSelectedItem: number | null;
    tasks: TaskItem[];
    guideCompleted: boolean;
}

type Props = {
    history: History;
    userProfileState: ProfileState.UserProfileModel;
    dashboardContainer: DashboardContainer;
    userType: UserTypeContainer;
} & typeof ProfileState.actionCreators &
    typeof EventsState.actionCreators;

export interface TaskItem {
    iconName: string;
    description: string;
    popover: Popovers;
    subtitle: string;
    completed: boolean;
    url?: string;
}

class OverlayMenu extends React.Component<Props, State> {
    ////////////////////////////////
    //////////////////////////////// State:
    ////////////////////////////////
    state = {
        showProgress: false,
        showChat: false,
        key: 0,
        items: {
            Chat: {
                icon: {
                    path: 'fa-comments-o',
                    type: IconType.FontAwesome
                } as Icon,
                notificationNumber: 0,
                tooltipText: 'Chat with Contacts',
                id: 'chat-btn'
            } as Item
        },
        tasks: [],
        selectedItem: null,
        prevSelectedItem: null,
        guideCompleted: false
    } as State;
    private OverlayMenuWrapperRef: typeof OverlayMenuWrapper | null | undefined;
    private ModalProgressGuideRef: ModalProgressGuide | null | undefined;
    ////////////////////////////////
    //////////////////////////////// Render:
    ////////////////////////////////
    public render() {
        return (
            <OverlayMenuWrapper
                ref={(node) => (this.OverlayMenuWrapperRef = node)}
                // key={this.state.key}
            >
                {Object.values(this.state.items)
                    .slice(0)
                    .reverse()
                    .map((item, index) => {
                        if (!item)
                            return null;

                        return (
                            <OverlayMenuItem key={`OverlayMenuItem+${index}`}>
                                <div id="new-chats-count" style={{ display: 'none' }} className="notification-number">

                                </div>
                                <button
                                    type="button"
                                    className="btn btn-default btn-circle btn-xl"
                                    style={{ 
                                        position: 'relative',
                                        backgroundColor: theme.colours.blue2,
                                        color: 'white'
                                    }}
                                    onClick={() => {
                                        this.setSelectedItem(index);
                                        if (this.ModalProgressGuideRef) {
                                            this.ModalProgressGuideRef.onClose();
                                        }
                                    }}
                                    id={item.id}
                                >
                                    {item.icon.type == IconType.FontAwesome ? (
                                        <div className='icon-with-text'>
                                            { (localStorage.getItem("chat-covers") && JSON.parse(localStorage.getItem("chat-covers") || "[]").length) ?
                                                <div className='logos'>
                                                    { JSON.parse(localStorage.getItem("chat-covers") || "[]").map(id => {
                                                        return (
                                                            <img src={ProfileApi.getProfileImageUrl(id)} />
                                                        )
                                                    }) }
                                                </div>
                                                :
                                                <i className={`fa ${item.icon.path}`} />
                                            }
                                            <span>{item.tooltipText}</span>
                                        </div>
                                    ) : (
                                        <div />
                                    )}
                                </button>
                            </OverlayMenuItem>
                        )
                    })}
                <MenuItemComponentHolder
                    className={`floating-btn ${
                        this.state.selectedItem !== null ? 'shown' : ''
                    }`}
                    selectedItem={this.state.selectedItem}
                    prevSelectedItem={this.state.prevSelectedItem}
                    items={this.state.items}
                >
                    {this.renderSelectedItem(this.state.selectedItem !== null)}
                </MenuItemComponentHolder>
            </OverlayMenuWrapper>
        );
    }
    ////////////////////////////////
    //////////////////////////////// Rendering:
    ////////////////////////////////
    private renderSelectedItem(visible: boolean) {
        return (
            <>
                <ModalProgressGuide
                    tasks={this.state.tasks}
                    ref={(node) => {
                        this.ModalProgressGuideRef = node;
                    }}
                    styleProps={{
                        display:
                            this.state.selectedItem == 1 ? 'block' : 'none',
                        width: '100%',
                        height: '100%'
                    }}
                    dashboardReloader={this.reload.bind(this)}
                />
                {/* {window.location.href.indexOf('/chat') === -1 && ( */}
                    <Chat
                        updateNotificationBadge={(count, decrease) => {
                            this.updateNotificationBadge(count, decrease);
                        }}
                        styleProps={{
                            display:
                                this.state.selectedItem == 0 ? 'block' : 'none',
                            width: '100%',
                            height: '100%'
                        }}
                        visible={visible}
                    />
                {/* )} */}
            </>
        );
    }
    ////////////////////////////////
    ////////////////////////////////
    ////////////////////////////////
    public refresh() {
        this.setState(
            (prevState: State) => {
                return { ...prevState, key: prevState.key++ };
            },
            async () => {
                await this.reload();
                this.forceUpdate();
            }
        );
    }
    ////////////////////////////////
    //////////////////////////////// Handling outside clicks:
    ////////////////////////////////
    componentDidMount() {
        document.addEventListener('mousedown', this.handleClick, false);
        if (this.propsInjected(this.props)) {
            this.reload();
        }
        this.props.setOverlayMenuRef(this);
    }

    componentDidUpdate(prevProps: Props) {
        if (!this.propsInjected(prevProps) && this.propsInjected(this.props)) {
            this.reload();
        }
        //console.log('componentDidUpdate:', this);
    }

    propsInjected = (props: Props) =>
        !!Object.keys(props.userProfileState).length;

    async reload() {
        const { dashboardContainer: dashboard, userType } = this.props;
        await this.props.dashboardContainer!.reload(this.props).then(() => {
            const taskItems = getTaskItems(dashboard, userType);
            this.setState({ tasks: taskItems }, this.updateNotificationNumbers);
            if (
                taskItems.length !== 0 &&
                taskItems.filter((task) => !task.completed).length === 0
            ) {
                SettingsAPI.update(
                    Setting.ProgressGuide_Completed,
                    'true'
                ).then((result) => {
                    //console.log('ProgressGuide_Completed:', result);
                });
            }
        });
    }

    resetChatCounter() {
        return;
        
        this.setState((prevState) => {
            return {
                ...prevState,
                items: {
                    ...prevState.items,
                    Chat: {
                        ...prevState.items.Chat,
                        notificationNumber: 0
                    }
                }
            };
        });
    }

    updateNotificationBadge(count: number, decrease?: boolean) {
        this.setState((prevState) => {
            let prevCount = prevState.items.Chat.notificationNumber || 0;
            return {
                ...prevState,
                items: {
                    ...prevState.items,
                    Chat: {
                        ...prevState.items.Chat,
                        notificationNumber: decrease ? prevCount - count : prevCount + count
                    }
                }
            };
        });
    }

    updateNotificationNumbers() {
        //console.log('this.state: ', this.state);
        // this.setState((prevState) => {
        //     return {
        //         ...prevState,
        //         items: {
        //             ...prevState.items,
        //             Guide: {
        //                 ...prevState.items.Guide,
        //                 notificationNumber: this.state.tasks.filter(
        //                     (task) => !task.completed
        //                 ).length
        //             }
        //         }
        //     };
        // });
    }

    setSelectedItem(number: number): void {
        // this.resetChatCounter();
        this.setState((prevState) => {
            const newSelectedItem =
                Object.values(this.state.items).length - number - 1;

            return {
                selectedItem:
                    prevState.selectedItem == newSelectedItem
                        ? null
                        : newSelectedItem,
                prevSelectedItem: prevState.selectedItem
            };
        });
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClick, false);
    }
    ////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////
    handleClick = (e) => {
        if (this.OverlayMenuWrapperRef.contains(e.target)) {
            return;
        }

        this.handleClickOutside();
    };

    handleClickOutside() {
        this.setState({ selectedItem: null });
        if (this.ModalProgressGuideRef) {
            this.ModalProgressGuideRef.onClose();
        }
    }
}
//////////////////////////////////////////////////////////////////
export const getTaskItems = (
    dashboardContainer: DashboardContainer,
    userType: UserTypeContainer
): TaskItem[] =>
    dashboardContainer &&
    userType &&
    userType.state.loaded &&
    dashboardContainer.state.loaded
        ? [
              // {
              //     iconName: 'fa fa-address-book',
              //     description: 'Email',
              //     popover: Popovers.verifyEmail,
              //     subtitle: 'Verfiy your email address.',
              //     completed: true
              // },
              {
                  iconName: 'far fa-address-card',
                  description: 'Set profile details',
                  popover: Popovers.setPhoto,
                  subtitle: 'Set your profile details and photo.',
                  completed: dashboardContainer.state.data!.profilePhotoExists,
                  url: '/profile'
              },
              {
                  iconName: 'fa fa-sitemap',
                  description: 'Create Organization',
                  popover: Popovers.createOrganisation,
                  subtitle:
                      'Confirm or create your organization profile to invite contacts and send offers.',
                  completed: dashboardContainer!.hasOrganisation,
                  url: '/organisation'
              },
              {
                  iconName: 'fa fa-clock',
                  description: 'Customise Time Presets',
                  popover: Popovers.setDefaultTimePresets,
                  subtitle:
                      'Customise the presets you apply to availability searches and offers.',
                  completed: dashboardContainer.state.data!.timePresetsSet,
                  url: '/settings/stpresets'
            },
              {
                  iconName: 'far fa-address-book',
                  description: 'Add Your Contacts',
                  popover: Popovers.inviteContacts,
                  subtitle:
                      'Invite your preferred contacts share their availability with you.',
                  completed:
                      !!dashboardContainer.state.data!.contactsReceiving ||
                      !!dashboardContainer.state.data!.contactsPending,
                  url: '/invite'
              },
              // {
              //     iconName: 'fas fa-shield-alt',
              //     description: 'Verify',
              //     popover: Popovers.verifyIdentity,
              //     subtitle:
              //         'You currently appear as unverified to contacts and have limited access until verified. Your contacts need to know it is you connecting with them.',
              //     completed: dashboardContainer.state.data!.verified
              // },
              {
                  iconName: 'fas fa-headset',
                  description: 'Add Your Agencies',
                  popover: Popovers.detailPreferredAgencies,
                  subtitle:
                      'When confirming offers with workers choose agencies to mutually engage through.',
                  completed:
                      dashboardContainer.state.data!.agencies > 0 ||
                      dashboardContainer.state.data!.agencies === -1,
                  url: '/agencies'
              },
              {
                  iconName: 'fa fa-briefcase',
                  description: 'Send an Instant Offer',
                  popover: Popovers.sendInstantOffer,
                  subtitle:
                      'Send your first instant work offer to your preferred contacts.',
                  completed: !!dashboardContainer.state.data!.offers,
                  url: '/offers?create=true'
              },

              {
                  iconName: 'fa fa-bell',
                  description: 'Availability Alerts',
                  popover: Popovers.setAvailabilityAlerts,
                  subtitle:
                      "You can configure when you receive alerts about changes in your contacts' availability.",
                  completed: dashboardContainer.state.data!.alertsSet,
                  url: '/settings/alerts'
              },
              {
                  iconName: 'fa fa-users',
                  description: 'Set up Teams to collaborate',
                  popover: Popovers.createTeams,
                  subtitle:
                      'Invite colleagues in your organization to share contacts. Create teams by department to share contacts and work better together.',
                  completed: !!dashboardContainer.state.data!.teams,
                  url: '/organisation/teams'
              }
              // {
              //     iconName: 'fa fa-list',
              //     description: 'Lists',
              //     popover: Popovers.createList,
              //     subtitle:
              //         'Speed up offers and availability searches by creating skill lists. Separate contacts into specific skill lists.',
              //     completed: false //!!dashboardContainer.state.data!.lists
              // },
              // {
              //     iconName: 'fa fa-comments-o',
              //     description: 'Chat',
              //     popover: Popovers.startChatting,
              //     subtitle:
              //         'Chat with any of your contacts at any time. They can initiate chat with you too.',
              //     completed: false //!!dashboardContainer.state.data!.startedChatting
              //}
          ]
        : [];
//////////////////////////////////////////////////////////////////
export default connect(
    (state: ApplicationState) => {
        return {
            userProfileState: state.profile.authenticatedUserProfile
        };
    },
    { ...ProfileState.actionCreators, ...EventsState.actionCreators }
)((props) => (
    <Subscribe to={[DashboardContainer, UserTypeContainer]}>
        {(dashboard: DashboardContainer, userType: UserTypeContainer) => (
            <OverlayMenu
                // @ts-ignore
                {...props}
                dashboardContainer={dashboard}
                userType={userType}
            />
        )}
    </Subscribe>
)) as any;
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////

const MenuItemComponentHolder = styled.div`
    overflow: visible;
    position: absolute;
    display: block;
    border-radius: 5px;
    background: none;
    border: 0;
    filter: drop-shadow(0 0 0.25rem rgba(106, 108, 111, 0.35));
    max-width: calc(100vw - 150px);
    height: ${(props) => {
        switch (props.selectedItem) {
            case 0:
                return `60rem`;
            case 1:
                return `35rem`;
        }
    }};
    top: ${(props) => {
        switch (props.selectedItem) {
            case 0:
                return `-62rem`;
            case 1:
                return `-37rem`;
        }
    }};
    //width: 60rem; // Should be distance from origin to left edge of screen - offset. How to get origin?
    width: ${(props) => {
        const xOffset = 1;
        const itemWidth = 10;
        const multiple = props.selectedItem * itemWidth;
        const right = xOffset + multiple;
        return `min(calc(100vw - ${right}rem - 4rem), 80rem);`;
    }};
    z-index: 1000;

    right: ${(props) => {
        const xOffset = 1;
        const itemWidth = 10;
        const multiple = props.selectedItem * itemWidth;
        return `${xOffset + multiple}rem`;
    }};

    opacity: 0;
    visibility: hidden;

    transition: opacity 333ms ease-in-out, width 20ms ease-in-out,
        height 333ms ease-in-out, top 333ms ease-in-out,
        visibility 0s ease-in-out 333ms, right 0s ease-in-out 333ms;

    &.shown {
        opacity: 1;
        visibility: visible;
        transition: ${(props) => {
            return `opacity 333ms ease-in-out, visibility 0s ease-in-out, width 20ms ease-in-out, top 333ms ease-in-out,
            height 333ms ease-in-out, right ${
                props.prevSelectedItem !== null ? 333 : 0
            }ms ease-in-out`;
        }};
    }

    @media (max-width: 768px) {
        max-height: calc(100vh - 244px);
        bottom: 104px;
        top: unset;
    }

    @media (max-width: 550px) {
        right: 0;
        width: 100%;
        max-width: 100%;
        min-width: 320px;
    }

`;

const OverlayMenuWrapper = styled.div`
    justify-content: flex-start;
    flex-direction: column;
    position: fixed;
    z-index: 3;

    @media print {
        display: none !important;
    }

    @media (min-width: 768px) {
        bottom: 6rem;
    }

    right: 2rem;
    bottom: 2rem;
`;

const OverlayMenuItem = styled.div`
    flex: 1;
    height: 100% !important;
    width: min-content;
    display: inline-block;
    margin: 1rem;

    transition: transform 0.25s linear, filter 0.25s linear;

    &:hover {
        transform: scale(1.2);
        filter: drop-shadow(0 0 0.25rem rgba(106, 108, 111, 0.35));
        //z-index: 1000;
    }

    .btn-circle.btn-xl {
        width: 80px;
        height: 80px;
        padding: 10px 16px;
        border-radius: 100%;
        font-size: 24px;
        line-height: 1.33;
        &:focus {
            outline: blue;
        }
    }

    .btn-circle {
        width: 30px;
        height: 30px;
        padding: 6px 0px;
        border-radius: 15px;
        text-align: center;
        font-size: 12px;
        line-height: 1.42857;
    }

    .icon-with-text {
        position: relative;
        
        i {
            position: relative;
            top: -19px;
        }

        .logos {
            position: relative;
            top: -19px;
            height: 24px;
            margin-bottom: -10px;
            top: -22px;

            img {
                width: 24px;
                height: 24px;
                border-radius: 100%;
                position: absolute;
                top: 0;
                left: 0;
                border: 1px solid #dddddd;

                &:nth-child(2) {
                    left: 11px;
                }

                &:nth-child(3) {
                    left: 22px;
                }
            }
        }

        span {
            position: absolute;
            bottom: -8px;
            left: -17px;
            width: 80px;
            font-size: 12px;
            line-height: 14px;
            white-space: break-spaces;
            text-align: center;
            display: inline-block;
        }
    }
`;
