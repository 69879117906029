/* eslint-disable */
import { ToastType } from 'react-toastify';
import fetch from 'cross-fetch';

import AuthApi from '../components/auth/Auth';
import * as Notifications from '../store/Notifications';
import { StorageKeys } from '../constants';
import Analytics from '../services/analytics.service';
import { ErrorResponse } from './BaseResponseTypes';

export type HTTP_VERB = 'GET' | 'PUT' | 'PATCH' | 'DELETE' | 'POST';

export type ApiVersion = '1.0';

const DEFAULT_API_VERSION: ApiVersion = '1.0';

const CustomHeaders = () => {
    return {
        'X-UE-TeamId': localStorage.getItem(StorageKeys.teamId) || '0'
    };
};

function handleApiAuth() {
    const auth = AuthApi.getToken();
    const isExpired =
        auth && auth.tokenType == 'Bearer' && AuthApi.isTokenExpired(auth);

    return { auth, isExpired };
}

function appendApiVersion(_, url: string, apiVersion?: ApiVersion) {
    return url.includes('?')
        ? `${url}&api-version=${apiVersion}`
        : `${url}?api-version=${apiVersion}`;
}

/**
 * Can be used to make a request to the API expecting a DTO response
 * Alternatively can be used to make a service call expecting a boolean success value
 */
async function request<TReq, TResp = boolean>(
    /**
     * TODO: Replace amount of arguments here with a single configuration object
     */
    url: string,
    verb: HTTP_VERB,
    requestObject: TReq | null,
    uiFailureMessage?: string,
    apiVersion: ApiVersion = DEFAULT_API_VERSION,
    returnApiError = false,
    displayApiError = false,
    displayApiErrorAndReturnApiError = false,
    returnRawResponse = false
): Promise<TResp> {
    const { auth, isExpired } = handleApiAuth();
    const path = appendApiVersion`${url}${apiVersion}`;

    if (isExpired || !auth) {
        return Promise.reject();
    }

    let impersonatedAccessToken = localStorage.getItem(
        'impersonatedAccessToken'
    );

    const req = {
        method: verb.toString(),
        headers: {
            Authorization: `${auth.tokenType} ${impersonatedAccessToken || auth.accessToken}`,
            'Content-Type': 'application/json',
            ...CustomHeaders()
        },
        ...(verb == 'GET'
            ? {}
            : {
                  body: JSON.stringify(requestObject)
              })
    };

    const response = await fetch(path, req);

    if (returnRawResponse) {
        // @ts-ignore
        return response;
    }

    const data = (await response.json().catch(() => response.ok)) as Promise<
        TResp
    >;

    if (response.ok) return data;

    Analytics.trackFailedRequest({
        url,
        verb,
        requestObject
    });

    const apiError = displayApiError ? data : null;

    /** Passes the API data back to the caller for manual error handling */
    if (returnApiError) return Promise.reject(data);

    // @ts-ignore
    Notifications.actionCreators.display(ToastType.ERROR, (data && data.error ? data.error : data) || uiFailureMessage || 'The request could not be performed.');

    if (displayApiErrorAndReturnApiError) {
        return Promise.reject(data);
    }

    /**
     * We must reject here to prevent the .then clause being called
     * meaning both a success and error message will show
     * We want to display the error message but continue as a rejected chain
     * https://stackoverflow.com/a/42171508/1902408
     */
    return Promise.reject('API request failed - notification was displayed');
}

/** Makes a service call and expects a successful HTTP Status Code response */
function uploadFile(
    url: string,
    file: Blob,
    uiFailureMessage: string,
    apiVersion: ApiVersion = DEFAULT_API_VERSION
): Promise<boolean> {
    const { auth } = handleApiAuth();
    if (!auth) return Promise.reject();
    const path = appendApiVersion`${url}${apiVersion}`;
    const formData = new FormData();
    formData.append('file', file);

    let impersonatedAccessToken = localStorage.getItem(
        'impersonatedAccessToken'
    );

    let otpAccessToken = localStorage.getItem(
        'access_token_otp'
    );

    if (otpAccessToken || impersonatedAccessToken || auth.accessToken) {
        localStorage.removeItem("timesheet-otp-link")
        localStorage.removeItem("timesheetWorkersForLastPeriod")
        localStorage.removeItem("timesheet-school")
        localStorage.removeItem("timesheet-school-id")
        localStorage.removeItem("timesheet-agency-id")
    }

    const req = {
        method: 'POST',
        headers: {
            Authorization: `${auth.tokenType} ${impersonatedAccessToken || otpAccessToken || auth.accessToken}`
        },
        body: formData
    };

    return fetch(path, req)
        .then((response) => {
            return response.ok;
        })
        .catch(function(error) {
            Notifications.actionCreators.display(
                ToastType.ERROR,
                uiFailureMessage
            );
            return error;
        });
}

const AuthenticatedFetch = {
    request,
    uploadFile
};

/**
 * Utility function to get the first error from an API response
 */
export function getFirstError(response?: ErrorResponse) {
    if (!response) return null;
    if (response.errors == null && response.detail != null)
        return response.detail;
    const errors = Object.values(response.errors);
    return Array.isArray(errors[0]) ? errors[0][0] : errors[0];
}

export default AuthenticatedFetch;
