/* eslint-disable */
/* eslint-disable @typescript-eslint/ban-ts-ignore */
import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import moment from 'moment';
import { toast } from 'react-toastify';
import {
    Button,
    ButtonToolbar,
    DropdownButton,
    MenuItem,
    Popover,
    OverlayTrigger
} from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import 'react-day-picker/lib/style.css';
import { RouteComponentProps } from 'react-router-dom';
import { WithContext as ReactTags } from 'react-tag-input';
import LookupsApi from '../../api/lookups/Lookups';
import LocationsApi from '../../api/locations/Locations';
import TimePresetApi from '../../api/timepresets/TimePresets';
import CompanyApi from '../../api/company/Company';
import OrganisationApi from '../../api/organisation/Organisation';

import { ApplicationState } from '../../store';
import * as ContactAvailabilityStore from '../../store/Availability';
import { DateFormats } from '../../constants';
import * as TimePresetsModels from '../../store/timepresets/Models';
import * as TimePresetsActionCreators from '../../store/timepresets/ActionCreators';
import Analytics from '../../services/analytics.service';
import NotificationApi from '../../api/notifications/Notifications';
import InternalTracker from '../../InternalTracker';
import * as ContactModels from '../../store/contacts/Models';
import * as ContactsActionCreators from '../../store/contacts/ActionCreators';
import * as RepresentedByActionCreators from '../../store/representedby/ActionCreators';
import * as RepresentedByStates from '../../store/representedby/States';
import theme from '../../css/theme';
import '../../css/ReactTagsOverrides.css';
import Dialog from '../ui-components/Dialog';
import SettingsApi from '../../api/settings/Settings';
import { Setting } from '../../api/settings/ResponseTypes';
import Utilities from '../../Utilities';
import AvailabilityAPI from '../../api/availability/Availability';
import TimePresetSlider from './TimePresetSlider';
import SimpleTooltip from '../ui-components/SimpleTooltip';
import { Modal } from 'react-bootstrap';
import { PubSubTopics } from '../../constants';
import { LookupItem } from 'src/api/lookups/ResponseTypes';
import Select from 'react-select'
import { Location, ThirdPartyLocationFlattened } from 'src/api/locations/ResponseTypes';
import { AvailabilityOrderingType } from '../../store/Availability';
import history from '../../history';
import { CircularProgress } from '@mui/material';
import { getValue, setValue } from 'src/db/KeyValueCache';
import { RepresentedByOrganisation } from 'src/api/availability/ResponseTypes';
import { MergedOrganisationDTO } from 'src/api/organisation/ResponseTypes';

export interface ToolbarProps {
    searchParams: ContactAvailabilityStore.AvailabilityParams;
    contactAvailabilityActions: typeof ContactAvailabilityStore.actionCreators;
    contactAvailability: ContactAvailabilityStore.ContactAvailabilityState;
    groups: ContactModels.ContactList[];
    groupActions: typeof ContactsActionCreators.actionCreators;
    representedByActions: typeof RepresentedByActionCreators.actionCreators;
    representedBy: RepresentedByStates.RepresentedByState;
    timePresets: TimePresetsModels.TimePresets;
    timePresetsActions: typeof TimePresetsActionCreators.actionCreators;
    contacts: ContactAvailabilityStore.ContactAvailabilityState['contacts'];
    selectedContacts: ContactAvailabilityStore.ContactAvailabilityState['contactsSelected'];
    key: RouteComponentProps['location']['key'];
    onPrint: () => void;
    onPrintV2: () => void;
    joyrideShowing?: boolean;
    showFilters?: boolean;
}

export type sortOption = 'Last updated' | 'Alphabetical' | 'Availability' | 'Distance from' | 'Rating';

interface LocalState {
    pdfTypeSelector: boolean;
    skillsQueryKeywords: string;
    skillSuggestions: ContactAvailabilityStore.Skill[];
    locations: Location[];
    thirdPartyLocations: ThirdPartyLocationFlattened[];
    filterDropdown: 'sort' | 'timepreset' | 'locations' | null;
    visible: boolean;
    failedToLoadImages: string[];
    searchParamsName: string;
    justRefreshedData: boolean;
    searchParamsPostcode: string | null;
    organisationSearchKeywords: string;
    userIsAgency: boolean;
    thirdPartyLocationsAll: MergedOrganisationDTO[];
    thirdPartyLocationsLoading: boolean,
    selectedThirdPartyLocationSchoolName: string | null;
}

export const sortOptions: sortOption[] = [
    'Alphabetical',
    'Last updated',
    'Availability',
    'Distance from',
    'Rating'
];

/**
 * Allows the user to change options for the summary grid
 */
export class AvailabilityGridToolbar extends React.Component<
    ToolbarProps,
    LocalState
> {
    private teamUpdatedSub?: string;

    constructor(props) {
        super(props);
        this.handleGroupIdChange = this.handleGroupIdChange.bind(this);
        this.getSelectedTimePreset = this.getSelectedTimePreset.bind(this);
        this.handleRepresentedByChange = this.handleRepresentedByChange.bind(this);
        this.refreshGrid = this.refreshGrid.bind(this);
    }

    initialState: LocalState = {
        pdfTypeSelector: false,
        skillsQueryKeywords: "",
        skillSuggestions: [],
        locations: [],
        thirdPartyLocations: [],
        filterDropdown: null,
        visible: true,
        failedToLoadImages: [],
        searchParamsName: "",
        justRefreshedData: false,
        searchParamsPostcode: null,
        userIsAgency: localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user") || "").organisationIsAgency : undefined,
        organisationSearchKeywords: "",
        thirdPartyLocationsAll: [],
        thirdPartyLocationsLoading: false,
        selectedThirdPartyLocationSchoolName: null
    };

    state: LocalState = this.initialState;

    get isDataStale() {
        return this.props.contactAvailability.stale;
    }

    refreshToolbar() {
        const nameFocused = document.activeElement?.id;
        this.setState({
            visible: (window as any).justChangedAgencyLocation ? true : false
        }, () => {
            (window as any).justChangedAgencyLocation = false;
            this.setState({
                visible: true
            }, () => {
                if (nameFocused === "search-by-name-input" || nameFocused === "search-by-skill-input") {
                    document.getElementById(nameFocused)?.focus();
                }
            })
        })
    }

    async componentDidMount() {
        this.props.contactAvailabilityActions.getCachedAvailability();

        this.props.timePresetsActions.getSearchTimePresets();
        this.props.groupActions.getLists();
        if (this.state.userIsAgency === false) {
            this.props.representedByActions.getRepresentedBy();
        }
        this.setPreviousTimePreset();

        if (this.teamUpdatedSub) {
            PubSub.unsubscribe(this.teamUpdatedSub);
        }

        this.teamUpdatedSub = PubSub.subscribe(
            PubSubTopics.teamIdUpdated,
            () => {
                this.props.groupActions.getLists();
                if (this.state.userIsAgency === false) {
                    this.props.representedByActions.getRepresentedBy();
                }
                const teamGroup = this.props.groups[0];
                if (teamGroup) {
                    this.handleGroupIdChange(teamGroup.id);
                    setTimeout(() => {
                        this.refreshToolbar();
                    })
                }
            }
        );

        if (localStorage.getItem("lastSkillFilters")) {
            this.props.contactAvailabilityActions.changeSkills(JSON.parse(localStorage.getItem("lastSkillFilters") || "[]"));
        }
    
        if (!window.location.pathname.startsWith("/external")) {
            const locationsCache = await getValue("orgLocations");
            if (locationsCache) {
                this.setState({
                    locations: locationsCache
                })
            }

            LocationsApi.getAll().then(locations => {
                if (locations && locations?.data?.locations) {
                    this.setState({
                        locations: locations.data.locations
                    })
                    setValue("orgLocations", locations.data.locations);
                }
            }).catch(() => { });

            if (this.state.userIsAgency) {
                this.loadThirdPartyLocations();
            }
        }

        if (this.state.userIsAgency === undefined) { 
            this.loadLocaluser();
        }            
    }

    async loadThirdPartyLocations() {
        const thirdPartyLocationsCache = await getValue("thirdPartyLocations");
        if (thirdPartyLocationsCache) {
            this.setState({
                thirdPartyLocations: thirdPartyLocationsCache
            })
        }

        LocationsApi.getAllThirdParty().then(thirdPartyLocations => {
            if (thirdPartyLocations && thirdPartyLocations.data && thirdPartyLocations.data.length > 0) {
                let flattenedThirdPartyLocations = thirdPartyLocations.data.map(loc => {
                    return {
                        ...loc.location,
                        locationFriendlyName: "[" + loc.organisationName + "]",
                        thirdPartyOrgId: loc.organisationId
                    } as ThirdPartyLocationFlattened
                })
                this.setState({
                    thirdPartyLocations: flattenedThirdPartyLocations
                })
                setValue("thirdPartyLocations", flattenedThirdPartyLocations);
            }
        }).catch(() => { });
    }

    loadLocaluser() {
        const user = localStorage.getItem("user");
        if (user) {
            const userIsAgency = JSON.parse(user).organisationIsAgency
            this.setState({
                userIsAgency: userIsAgency
            })
            if (userIsAgency) {
                this.loadThirdPartyLocations();
            }
        } else {
            setTimeout(() => {
                this.loadLocaluser();
            }, 250)
        }
    }

    setPreviousTimePreset() {
        let lastTimepreset = localStorage.getItem("dashboard") ? (JSON.parse(localStorage.getItem('dashboard') || '').timePresetDefault || "") : "";
        if (lastTimepreset && lastTimepreset !== this.props.searchParams.timePreset.id) {
            let timePresetObj = this.props.timePresets.presets.filter(item => item.id === lastTimepreset)[0];
            if (!timePresetObj) {
                setTimeout(() => { this.setPreviousTimePreset() }, 250);
            } else {
                this.props.contactAvailabilityActions.changeTimePreset(timePresetObj);
            }
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.key !== prevProps.key) {
            this.setState({
                ...this.initialState
            });
        }

        if (JSON.stringify(this.props.searchParams) !== JSON.stringify(prevProps.searchParams)) {
            this.refreshToolbar();
        }

        if (JSON.stringify(this.props.timePresets.presets) !== JSON.stringify(prevProps.timePresets.presets)) {
            if (localStorage.getItem("changePresetOnLoad")) {
                const presetId = localStorage.getItem("changePresetOnLoad");
                localStorage.removeItem("changePresetOnLoad");
                if (this.props.timePresets.presets.filter(item => item.id === presetId).length > 0) {
                    this.props.contactAvailabilityActions.changeTimePreset(this.props.timePresets.presets.filter(item => item.id === presetId)[0]);
                }
            }
        }
        
        if (prevProps.contactAvailability.stale && !this.props.contactAvailability.stale) {
            this.setState({
                justRefreshedData: true
            })
            setTimeout(() => {
                this.setState({
                    justRefreshedData: false
                })
            }, 2000)
        }
    }

    print() {
        this.props.onPrint();
    }

    async downloadCSV() {

        InternalTracker.trackEvent("Availability CSV Downloaded")

        let confirmMessage: string = "";
        let rows: string[][] = [];
        rows.push(["Name", "Event Type", "Start", "End"])

        // let start = Utilities.formatDate(this.props.searchParams.start.toDate(), "YYYY-MM-DD")
        let start = Utilities.formatDate(new Date(), "YYYY-MM-DD");
        let days = 7;
        let end = Utilities.formatDate(Utilities.dateAdd(new Date(start), "day", days), "YYYY-MM-DD");
        const contactsSelected =
            this.props.selectedContacts.length !== 0 ?
                this.props.selectedContacts.map(item => item.contact.userId) :
                this.props.contacts.map(item => item.contact.userId);

        confirmMessage += "This CSV will contain " + (this.props.selectedContacts.length === 0 ? "all" : "the selected " + this.props.selectedContacts.length) + " contact's events";
        confirmMessage += " between" + start + " to " + end + ". You can adjust the filters above to modify these values";

        if (window.confirm(confirmMessage)) {
            let data = await AvailabilityAPI.getEventsFromContacts(contactsSelected, start, end);
            if (data) {
                for (let i = 0; i < data.length; i++) {
                    const contactElement = data[i];
                    if (contactElement.events) {
                        // @ts-ignore
                        for (let j = 0; j < contactElement.events.length; j++) {
                            const eventElement = contactElement.events[j];
                            rows.push([contactElement.profile.firstName + " " + contactElement.profile.lastName, eventElement.eventType, eventElement.start, eventElement.end])
                        }
                    }
                }
                let csvContent = "data:text/csv;charset=utf-8," + rows.map(e => e.join(",")).join("\n");
                var encodedUri = encodeURI(csvContent);
                var link = document.createElement("a");
                link.setAttribute("href", encodedUri);
                link.setAttribute("download", "availability.csv");
                document.body.appendChild(link);
                link.click();
            }
        }

    }


    printV2() {

        let confirmMessage: string = "";

        let start = Utilities.formatDate(new Date(), "YYYY-MM-DD");
        let days = 7;
        let end = Utilities.formatDate(Utilities.dateAdd(new Date(start), "day", days), "YYYY-MM-DD");

        let startHour = this.props.searchParams.timePreset.startHour;
        let endHour = this.props.searchParams.timePreset.endHour;

        // No overnights allowed, revert to 24 report
        if (startHour > endHour) {
            startHour = 0;
            endHour = 23
        }

        confirmMessage += "This PDF will contain " + (this.props.selectedContacts.length === 0 ? "all" : "the selected " + this.props.selectedContacts.length) + " contact's availability data";
        confirmMessage += " between " + startHour + ":00 and " + endHour + ":59, on each day from "
        confirmMessage += start + " to " + end + ". You can adjust the filters above to modify these values";

        if (window.confirm(confirmMessage)) {
            localStorage.setItem("availability-print-pdf", JSON.stringify({
                contacts: this.props.contacts.map(item => item.contact.userId),
                contactsSelected: 
                    this.props.selectedContacts.length !== 0 ?
                        this.props.selectedContacts.map(item => item.contact.userId) :
                        this.props.contacts.map(item => item.contact.userId),
                start: start,
                days: days,
                startHour: startHour,
                endHour: endHour,
                originalTimePreset: 
                    (startHour < endHour) ?
                        (this.props.searchParams.timePreset.startHour + ":" + this.props.searchParams.timePreset.startMinute + " - " + this.props.searchParams.timePreset.endHour + ":" + this.props.searchParams.timePreset.endMinute) :
                        ("00:00 - 23:59")
            }))
            this.props.onPrintV2();
        }

    }

    static getDerivedStateFromProps(
        nextProps: ToolbarProps,
        prevState: LocalState
    ) {
        // TODO: Refactor this, getDerivedState is not appropriate as we are not updating state
        if (
            nextProps.timePresets !== undefined &&
            nextProps.timePresets.loaded &&
            nextProps.searchParams.timePreset.id === ''
        ) {
            nextProps.contactAvailabilityActions.changeTimePreset(
                nextProps.timePresets.presets[0]
            );
        }

        return prevState;
    }

    refreshGrid() {
        this.props.contactAvailabilityActions.getAvailability();
    }

    handleGroupIdChange(groupId: number) {
        this.props.contactAvailabilityActions.changeList(groupId);
        this.props.contactAvailabilityActions.clearSelectedContacts();
        InternalTracker.trackEvent("Availability Filter Changed", {
            type: "group",
            value: groupId.toString()
        })
    }

    handleRepresentedByChange(organisationId: string) {
        this.props.contactAvailabilityActions.changeRepresentingAgencyId(organisationId);
        this.props.contactAvailabilityActions.clearSelectedContacts();
        InternalTracker.trackEvent("Availability Filter Changed", {
            type: "representedby",
            value: organisationId
        })
    }

    handleTimePresetIdChange(preset: TimePresetsModels.TimePreset) {
        this.props.contactAvailabilityActions.changeTimePreset(preset);

        if (!window.location.pathname.startsWith("/external")) {
            SettingsApi.update(Setting.TimePresets_Default, preset.id);
            if (localStorage.getItem("dashboard")) {
                localStorage.setItem('dashboard', JSON.stringify({
                    ...JSON.parse(localStorage.getItem('dashboard') || ''),
                    timePresetDefault: preset.id
                }))
            }

            InternalTracker.trackEvent("Availability Filter Changed", {
                type: "timepreset",
                value: preset.id,
                start: preset.startHour + ":" + preset.startMinute,
                end: preset.endHour + ":" + preset.endMinute
            })
        }
    }

    getSelectedTimePreset() {
        const preset = this.props.timePresets.presets.find((p) => {
            return p.id == this.props.searchParams.timePreset.id;
        });
        return preset === undefined ? this.props.timePresets[0] : preset;
    }

    getSelectedGroup(): ContactModels.ContactList {
        return this.props.groups.length > 0
            ? this.props.groups.filter((group) => {
                  return group.id == this.props.searchParams.groupId;
              })[0]
            : { id: -1, name: '', count: 0, loaded: false, contactGroupName: '' };
    }

    selectAllContacts = () => {
        InternalTracker.trackEvent("All Contacts Selected")
        this.props.contactAvailabilityActions.selectAllContacts();
    };

    clearAllContacts = () => {
        InternalTracker.trackEvent("All Contacts Cleared")
        this.props.contactAvailabilityActions.clearSelectedContacts();
    };

    nudgeSelected = () => {
        this.props.selectedContacts.forEach(({ contact: { id, fullName } }) => {
            InternalTracker.trackEvent("Sent Nudge", {
                contactId: id
            })

            NotificationApi.nudgeContact(id).then(() => {
                toast.success(`${fullName} nudged`);
            });
        });
    };

    offerSelected = () => {

        if (this.props.selectedContacts.length === 0) {

            alert("Select at least one contact you want to send an offer to.");
            
        } else {

            InternalTracker.trackEvent("Offer Creation Started", {
                source: "availabilitygird",
                contactIds: this.props.selectedContacts.map(item => item.contact.id)
            })
    
            window.open("/offers#create/worker/" + this.props.selectedContacts.map(item => item.contact.userId).join(","));

        }

    };

    private formatTimePresetName(preset: TimePresetsModels.TimePreset) {
        return preset !== undefined ? (
            `[${preset.startTime} - ${preset.endTime}] ${preset.name}`
        ) : (
            'Loading...'
        );
    }

    changeName = (name: string, force?: boolean) => {
        this.setState({
            searchParamsName: name
        })

        if (!force) {
            clearTimeout((window as any).nameChangeDebounce);
            (window as any).nameChangeDebounce = setTimeout(() => {
                this.changeName(name, true);
            }, 400)
            return;
        }
        console.log("Change name to " + name);
        InternalTracker.trackEvent("Availability Filter Changed", {
            type: "name",
            value: name
        })
        this.props.contactAvailabilityActions.changeName(name);
    }

    changeOrgQuery = async (name: string, force?: boolean) => {
        this.setState({
            organisationSearchKeywords: name
        })

        if (!force) {
            clearTimeout((window as any).nameChangeDebounce);
            (window as any).nameChangeDebounce = setTimeout(() => {
                this.changeOrgQuery(name, true);
            }, 400)
            return;
        }
        InternalTracker.trackEvent("Merged Organisations Searched", {
            value: name
        })
        if (name) {
            this.setState({
                thirdPartyLocationsLoading: true
            })
            const data = await OrganisationApi.searchOrganisationsByName(name);
            this.setState({
                thirdPartyLocationsAll: data,
                thirdPartyLocationsLoading: false
            })
        }
    }

    async getSkillSuggestions(keyword: string, force?: boolean) {

        if (!force) {
            clearTimeout((window as any).getSkillSuggestionsDebounce);
            (window as any).getSkillSuggestionsDebounce = setTimeout(() => {
                this.getSkillSuggestions(keyword, true);
            }, 400)
            return;
        }

        if (keyword.trim().length !== 0) {
            let data = await LookupsApi.subSectors(this.state.skillsQueryKeywords.trim(), 0);
            // @ts-ignore
            if (data && data.data && keyword.trim() === this.state.skillsQueryKeywords.trim()) {
                // @ts-ignore
                this.setState({ skillSuggestions: data.data.map(item => { return { id: item.id + "", text: item.value } }) })   
            } else {
                this.setState({ skillSuggestions: [] })
            }
        }

        setTimeout(() => {
            if (keyword === "") {
                let skillsWithoutPendingInput: ContactAvailabilityStore.Skill[] = this.props.searchParams.skills || [];
                skillsWithoutPendingInput = skillsWithoutPendingInput.filter(item => !item.pendingAdd);
                if (this.props.searchParams.skills?.length !== skillsWithoutPendingInput.length) {
                    this.props.contactAvailabilityActions.changeSkills(skillsWithoutPendingInput);
                    this.props.contactAvailabilityActions.clearSelectedContacts();
                }
            } else {
                let newSkills: ContactAvailabilityStore.Skill[] = this.props.searchParams.skills || [];
                newSkills = newSkills.filter(item => !item.pendingAdd);
                const alreadyExists = newSkills.find(item => item.text === keyword);
                if (!alreadyExists) {
                    newSkills.push({ text: keyword, id: keyword, pendingAdd: true })
                    this.props.contactAvailabilityActions.changeSkills(newSkills);
                    this.props.contactAvailabilityActions.clearSelectedContacts();
                }
            }
        }, 1000)
    }

    render() {
        const selectedPreset = this.getSelectedTimePreset();
        const selectedGroup = this.getSelectedGroup();
        const params = this.props.searchParams;
        const selectedLocation = this.state.locations.find(loc => loc.id === this.props.searchParams.hirerLocationId);
        
        if (!this.state || !this.state.visible) {
            return null;
        }

        return (
            <Wrapper>
                { (window.location.pathname.startsWith("/external") && !window.location.pathname.includes("/worker")) &&
                    <div 
                        className="ribbon ribbon-top-right"
                        onClick={() => {
                            window.open("/", "_blank");
                        }}
                    >
                        <span>Powered By <br /><strong>Updatedge</strong></span>
                    </div>
                }
                { (window.location.pathname.startsWith("/external")) &&
                    <React.Fragment>
                        <h2
                            style={{
                                textAlign: 'center'
                            }}
                        >
                            {!window.location.pathname.includes("/worker") ? "Availability Next Week" : " Your Availability Next Week"}
                        </h2>
                        <p
                            style={{
                                textAlign: 'center',
                                marginBottom: window.location.pathname.includes("/worker") ? 0 : 28,
                                fontSize: 16,
                                fontWeight: 700
                            }}
                        >
                            {!window.location.pathname.includes("/worker") ? "Search your contacts availability by date, skills, qualifications; click any name to see more details" : " Please confirm or edit your availability to build confidence with your contacts"}
                        </p>
                    </React.Fragment>
                }
                { (this.isDataStale || this.state.justRefreshedData) && 
                    <div className={this.state.justRefreshedData ? "nonblocking-loaded-wrapper" : "nonblocking-loading-wrapper"} style={{ top: -80, right: 0 }}>
                        { (this.state.justRefreshedData) ?
                            <i className="fas fa-check-circle"></i>
                            :
                            <CircularProgress style={{ width: 24, height: 24 }} />
                        }
                        { (this.state.justRefreshedData) ?
                            <span>
                                Just Refreshed
                            </span>
                            :
                            <span>
                                Last Updated
                                { (this.props.contactAvailability.lastFetched) &&
                                    ' ' + Utilities.timeSince(this.props.contactAvailability.lastFetched)
                                }
                            </span>
                        }
                    </div>
                }
                { (!window.location.pathname.includes("/worker") && this.props.showFilters) &&
                <div className='filters clearfix' style={ this.props.joyrideShowing ? { paddingTop: 40 } : { } } data-external={window.location.pathname.startsWith("/external")}>
                    <div className="filter skills">
                        <h3>
                            <img src='/icons/users-line-solid.svg' />
                            <span>Name</span>
                        </h3>
                        <div className="simple-input">
                            <input
                                type="text"
                                value={this.state.searchParamsName || this.props.searchParams.name}
                                id="search-by-name-input"
                                onChange={({ target: { name, value } }) => {
                                    this.changeName(Utilities.capitalizeEachFirstLetter(value));
                                }}
                                placeholder="Type to search by name"
                            />
                            { (this.props.searchParams.name) &&
                                <i onClick={() => {
                                    this.changeName("", true)
                                    setTimeout(() => {
                                        const el = document.getElementById("search-by-name-input") as HTMLInputElement;
                                        if (el) {
                                            el.focus();
                                        }
                                    }, 100)
                                }} className='fas fa-times-circle' />
                            }
                        </div>
                    </div>
                    <div className="filter skills">
                        <h3>
                            <img src='/icons/head-side-virus-solid.svg' />
                            <span>Skills & Qualifications</span>
                        </h3>
                        <div className='simple-input'>
                            <input 
                                value={this.state.skillsQueryKeywords} 
                                placeholder='Type to search by skills'
                                id="search-by-skill-input"
                                onChange={(e) => {
                                    InternalTracker.trackEvent("Availability Filter Changed", {
                                        type: "skill",
                                        value: e.target.value
                                    })
                                    this.setState({
                                        skillsQueryKeywords: e.target.value
                                    }, async () => {
                                        this.getSkillSuggestions(this.state.skillsQueryKeywords);
                                    })
                                }}
                            />
                            { (this.state.skillsQueryKeywords) &&
                                <i onClick={() => {
                                    InternalTracker.trackEvent("Availability Filter Changed", {
                                        type: "skill",
                                        value: ""
                                    })
                                    this.setState({
                                        skillsQueryKeywords: ""
                                    }, async () => {
                                        this.getSkillSuggestions(this.state.skillsQueryKeywords);
                                        setTimeout(() => {
                                            const el = document.getElementById("search-by-skill-input") as HTMLInputElement;
                                            if (el) {
                                                el.focus();
                                            }
                                        }, 100)
                                    })
                                }} className='fas fa-times-circle' />
                            }
                        </div>
                    </div>

                    <div className="filter timepreset">
                        <h3>
                            <img src='/icons/clock-solid.svg' />
                            <span>Times</span>
                        </h3>
                        <div className='simple-select'>
                            <select 
                                onClick={(e) => {
                                    if (this.props.searchParams.timePreset.id === "custom") {
                                        e.preventDefault();
                                        // @ts-ignore
                                        e.target.blur();
                                        this.setState({
                                            filterDropdown: "timepreset"
                                        });
                                    }
                                }}
                                defaultValue={this.props.searchParams.timePreset.id}
                                onChange={(e) => {
                                    const preset = this.props.timePresets.presets.find(p => p.id === e.target.value);
                                    if (preset) {
                                        this.handleTimePresetIdChange(preset);
                                        if (preset.name === "Custom") {
                                            this.setState({
                                                filterDropdown: "timepreset"
                                            });
                                        }
                                    }
                                }}
                            >
                                {this.props.timePresets.presets.map((preset, i) => {
                                    return (
                                        <option value={preset.id}>
                                            {this.formatTimePresetName(preset)}
                                        </option>
                                    )
                                }) }
                            </select>
                        </div>
                        { (this.state.filterDropdown === "timepreset") &&
                            <div className='filter-dropdown'>
                                <h3>Set the required start, and end time by adjusting the below slider</h3>
                                <TimePresetSlider
                                    compactDrag={true}
                                    overnight={ this.props.searchParams.timePreset.startHour > this.props.searchParams.timePreset.endHour /*this.props.searchParams.overnight || false*/}
                                    values={[this.props.searchParams.timePreset.startHour + this.props.searchParams.timePreset.startMinute / 60, this.props.searchParams.timePreset.endHour + this.props.searchParams.timePreset.endMinute / 60]}
                                    onFinalChange={(values) => {
                                        let customPreset = this.props.timePresets.presets.find(preset => preset.id === "custom");
                                        if (customPreset) {
                                            customPreset!.startHour = Math.floor(values[0]);
                                            customPreset!.startMinute = Utilities.getDecimal(values[0]) * 60;
                                            customPreset!.endHour = Math.floor(values[1]);
                                            customPreset!.endMinute = Utilities.getDecimal(values[1]) * 60;

                                            customPreset.startMinute = Math.round(customPreset.startMinute);
                                            customPreset.endMinute = Math.round(customPreset.endMinute);

                                            customPreset!.startTime = 
                                                (customPreset.startHour < 10 ? "0" + customPreset.startHour : customPreset.startHour) + 
                                                ":" +
                                                (customPreset.startMinute < 10 ? "0" + customPreset.startMinute : customPreset.startMinute);
                                            customPreset!.endTime = 
                                                (customPreset.endHour < 10 ? "0" + customPreset.endHour : customPreset.endHour) + 
                                                ":" +
                                                (customPreset.endMinute < 10 ? "0" + customPreset.endMinute : customPreset.endMinute);
                                            this.handleTimePresetIdChange(
                                                customPreset
                                            )
                                        }
                                    }}
                                    onChange={(values) => {

                                    }}
                                />
                                <div className='buttons'>
                                    <button className='reset' onClick={() => {
                                        this.setState({
                                            filterDropdown: null
                                        })
                                        if (window.location.pathname.startsWith("/external")) {
                                            this.handleTimePresetIdChange(this.props.timePresets.presets[1] ? this.props.timePresets.presets[1] : this.props.timePresets.presets[0]);
                                        } else {
                                            this.handleTimePresetIdChange(this.props.timePresets.presets[0]);
                                        }
                                    }}>Back to Presets</button>
                                    { (!window.location.pathname.startsWith("/external")) &&
                                        <button onClick={() => {
                                            const name = prompt("Please enter a name for this preset");
                                            if (!name || !name.trim()) {
                                                toast.error("Please enter a name for this preset");
                                                return;
                                            }

                                            const newPreset = {
                                                name,
                                                startHour: this.props.searchParams.timePreset.startHour,
                                                startMinute: this.props.searchParams.timePreset.startMinute,
                                                endHour: this.props.searchParams.timePreset.endHour,
                                                endMinute: this.props.searchParams.timePreset.endMinute
                                            }

                                            TimePresetApi.newPresetCreate(
                                                newPreset
                                            ).then((preset) => {
                                                toast.success("Preset created");
                                                this.props.timePresetsActions.getSearchTimePresets();
                                                // @ts-ignore
                                                localStorage.setItem("changePresetOnLoad", preset);
                                            }).catch((err) => {
                                                toast.error("Error creating preset");
                                            })
                                            this.setState({
                                                filterDropdown: null
                                            })
                                        }}>Save{' '}
                                            {this.props.searchParams.timePreset.startHour < 10 ? 
                                                ("0" + this.props.searchParams.timePreset.startHour) : 
                                                this.props.searchParams.timePreset.startHour}:
                                            {Math.round(this.props.searchParams.timePreset.startMinute) < 10 ? 
                                                ("0" + Math.round(this.props.searchParams.timePreset.startMinute)) :
                                                Math.round(this.props.searchParams.timePreset.startMinute)}
                                            -
                                            {this.props.searchParams.timePreset.endHour < 10 ? 
                                                ("0" + this.props.searchParams.timePreset.endHour) : 
                                                this.props.searchParams.timePreset.endHour}:
                                            {Math.round(this.props.searchParams.timePreset.endMinute) < 10 ? 
                                                ("0" + Math.round(this.props.searchParams.timePreset.endMinute)) : 
                                                Math.round(this.props.searchParams.timePreset.endMinute)}{' '}as Preset
                                        </button>
                                    }
                                    <button onClick={() => {
                                        this.setState({
                                            filterDropdown: null
                                        });
                                    }}>Apply & Close</button>
                                </div>
                            </div>
                        }
                    </div>

                    { (!window.location.pathname.startsWith("/external") && this.state.userIsAgency === false) &&
                        <div className="filter representedby">
                            <h3>
                                <img src='/icons/people-arrows-solid.svg' />
                                <span>Representing Agencies</span>
                            </h3>
                            <div className='simple-select'>
                                <select 
                                    defaultValue={this.props.searchParams.representingAgencyId}
                                    onChange={(e) => {
                                        const id = e.target.value;
                                        this.handleRepresentedByChange(id);
                                    }}
                                >
                                    {[{
                                        organisationId: "0",
                                        organisationName: "Any Agency",
                                        confirmedAt: ""
                                    } as RepresentedByOrganisation].concat(this.props.representedBy.data).map((representingAgency, i) => {
                                        return (
                                            <option value={representingAgency.organisationName}>
                                                {representingAgency.organisationName}
                                            </option>
                                        )
                                    }) }
                                </select>
                            </div>
                        </div>
                    }

                    <div className="filter sort-by">
                        <h3>
                            <img src='/icons/arrow-down-wide-short-solid.svg' />
                            <span>Contact Sort Order</span>
                        </h3>
                        <div className='simple-select'>
                            <select 
                                onClick={(e) => {
                                    if (this.props.searchParams.sortOrder === 3) {
                                        e.preventDefault();
                                        // @ts-ignore
                                        e.target.blur();
                                        this.setState({
                                            filterDropdown: "locations"
                                        });
                                    }
                                }}
                                defaultValue={this.props.searchParams.sortOrder}
                                onChange={(e) => {
                                    const valueIds = ['Alphabetical', 'Last updated', 'Availability', 'Distance from', 'Rating']
                                    const value = e.target.value;
                                    const i = parseInt(value) //  valueIds.indexOf(value);

                                    InternalTracker.trackEvent("Availability Sort Order Changed", {
                                        value: i === 0 ? "Alphabetical" : i === 1 ? "LastUpdated" : i === 2 ? "MostAvailable" : i === 3 ? "DistanceFrom" : "Rating"
                                    });

                                    this.props.contactAvailabilityActions.changeSortOrder(i === 0 ? AvailabilityOrderingType.Alphabetical : i === 1 ? AvailabilityOrderingType.LastUpdated : i === 2 ? AvailabilityOrderingType.MostAvailable : i === 3 ? AvailabilityOrderingType.LocationFrom : AvailabilityOrderingType.Rating);
                                    this.props.contactAvailabilityActions.clearSelectedContacts();

                                    if (i === 3) {
                                        this.setState({ filterDropdown: "locations" })
                                    }
                                }}
                            >
                                {sortOptions.map((so, i) => {
                                    return (
                                        <option value={i.toString()}>
                                            {so}
                                        </option>
                                    )
                                }) }
                            </select>
                        </div>
                        { (this.props.searchParams.sortOrder === AvailabilityOrderingType.LocationFrom && this.state.filterDropdown === "locations") &&
                            <div className='filter-dropdown' data-agency={this.state.userIsAgency ? "true" : "false"}>
                                <h3>Set the location to sort by</h3>
                                <div className='locations-list'>
                                    {this.state.locations.map((loc, i) => {
                                        return (
                                            <div 
                                                className='location' 
                                                data-selected={loc.id === this.props.searchParams.hirerLocationId}
                                                onClick={() => {
                                                    this.props.contactAvailabilityActions.changeHirerLocationId(loc.id);
                                                    this.props.contactAvailabilityActions.clearSelectedContacts();
                                                }}
                                            >
                                                { (this.state.failedToLoadImages.indexOf(loc.id) > -1) ?
                                                    <div className='no-img'>
                                                        <p>Map is not Available</p>
                                                    </div>
                                                    :
                                                    <img 
                                                        src={LocationsApi.getStaticMapUrl(loc.id)} 
                                                        onError={() => {
                                                            this.setState({
                                                                failedToLoadImages: [...this.state.failedToLoadImages, loc.id]
                                                            })
                                                        }}
                                                    />
                                                }
                                                <label>{loc.locationFriendlyName} {loc.locationPlaceName}</label>
                                            </div>
                                        )
                                    }) }
                                </div>
                                
                                { (this.state.userIsAgency) &&
                                    <React.Fragment>
                                        <h3 
                                            style={{
                                                marginTop: 20
                                            }}
                                        >
                                            Sort by Hirers' Locations
                                        </h3>
                                        <div className='simple-input'>
                                            <input
                                                type="text"
                                                value={this.state.organisationSearchKeywords}
                                                id="search-by-hirer-org-input"
                                                onChange={({ target: { name, value } }) => {
                                                    this.changeOrgQuery(value);
                                                }}
                                                onBlur={ async () => {
                                                    
                                                }}
                                                placeholder="Search across all organisations"
                                                style={{
                                                    background: "gainsboro",
                                                    padding: "8px 12px",
                                                    maxWidth: 240,
                                                    color: "black",
                                                    marginBottom: 12
                                                }}
                                            />
                                        </div>
                                        { ((this.state.thirdPartyLocationsLoading || this.state.thirdPartyLocationsAll) && this.state.organisationSearchKeywords) ?
                                            <div className='locations-list'>
                                                { (this.state.organisationSearchKeywords && !this.state.thirdPartyLocationsLoading && !this.state.thirdPartyLocationsAll.length) &&
                                                    <div className='loading'>
                                                        No Results
                                                    </div>
                                                }
                                                { (this.state.thirdPartyLocationsLoading) &&
                                                    <div className='loading'>
                                                        <i className="fa fa-spinner fa-spin" />
                                                        Loading Results
                                                    </div>
                                                }
                                                { this.state.thirdPartyLocationsAll.map((org, i) => {
                                                    const website = org.email ? (org.email.includes("@") ? org.email.split("@")[1] : org.email) : null;
                                                    return (
                                                        <div 
                                                            className='location' 
                                                            onClick={() => {
                                                                (window as any).justChangedAgencyLocation = true;
                                                                this.setState({
                                                                    searchParamsPostcode: org.postcode,
                                                                    filterDropdown: null,
                                                                    selectedThirdPartyLocationSchoolName: org.name
                                                                })
                                                                localStorage.setItem("lastOrganisationSearchSelect", JSON.stringify(org));
                                                                this.props.contactAvailabilityActions.changePostcode(org.postcode || "");
                                                                this.props.contactAvailabilityActions.clearSelectedContacts();
                                                            }}
                                                        >
                                                            { (org.id || website) &&
                                                                <img className='org-image' src={org.id ? CompanyApi.getOrganisationProfileImageUrl(org.id) : OrganisationApi.getExtOrgPicture(website || "") } />
                                                            }
                                                            <label className='alt'>
                                                                {org.name}
                                                                <span>{org.postcode} [{org.internal ? "Signed Up" : "External"}]</span>
                                                            </label>
                                                        </div> 
                                                    )
                                                }) }
                                            </div> :
                                            <div className='locations-list'>
                                                {this.state.thirdPartyLocations.map((loc, i) => {
                                                    return (
                                                        <div 
                                                            className='location' 
                                                            data-selected={loc.id === this.props.searchParams.hirerLocationId}
                                                            onClick={() => {
                                                                (window as any).justChangedAgencyLocation = true;
                                                                this.props.contactAvailabilityActions.changeHirerLocationId(loc.id);
                                                                this.props.contactAvailabilityActions.clearSelectedContacts();
                                                                this.setState({
                                                                    selectedThirdPartyLocationSchoolName: loc.locationPlaceName
                                                                })
                                                            }}
                                                        >
                                                            { (this.state.failedToLoadImages.indexOf(loc.id) > -1) ?
                                                                <div className='no-img'>
                                                                    <p>Map is not Available</p>
                                                                </div>
                                                                :
                                                                <img 
                                                                    src={LocationsApi.getStaticMapUrl(loc.id)} 
                                                                    onError={() => {
                                                                        this.setState({
                                                                            failedToLoadImages: [...this.state.failedToLoadImages, loc.id]
                                                                        })
                                                                    }}
                                                                />
                                                            }
                                                            <img className='org-image' src={CompanyApi.getOrganisationProfileImageUrl(loc.thirdPartyOrgId)} />
                                                            <label>{loc.locationFriendlyName} {loc.locationPlaceName}</label>
                                                        </div>
                                                    )
                                                }) }
                                            </div>
                                        }

                                        <h3 
                                            style={{
                                                marginTop: 20
                                            }}
                                        >
                                            Sort by Postcode
                                        </h3>
                                        <div className='simple-input'>
                                            <input
                                                type="text"
                                                value={this.state.searchParamsPostcode !== null ? this.state.searchParamsPostcode : (this.props.searchParams.postcode || "")}
                                                id="search-by-postcode-input"
                                                onChange={({ target: { name, value } }) => {
                                                    this.setState({
                                                        searchParamsPostcode: value,
                                                        selectedThirdPartyLocationSchoolName: null
                                                    })
                                                }}
                                                placeholder="Type to search by postcode"
                                                style={{
                                                    background: "gainsboro",
                                                    padding: "8px 12px",
                                                    maxWidth: 240,
                                                    color: "black"
                                                }}
                                            />
                                        </div>
                                    </React.Fragment>
                                }
                                <div className='buttons'>
                                    <button className='reset' onClick={() => {
                                        this.setState({
                                            filterDropdown: null,
                                            searchParamsPostcode: null,
                                            selectedThirdPartyLocationSchoolName: null
                                        })
                                        this.props.contactAvailabilityActions.changeSortOrder(1);
                                        this.props.contactAvailabilityActions.clearSelectedContacts();
                                    }}>Reset Sorting</button>
                                    <button onClick={() => {
                                        if (this.state.searchParamsPostcode && this.state.searchParamsPostcode.trim().length > 0) {
                                            this.props.contactAvailabilityActions.changePostcode(this.state.searchParamsPostcode);
                                            this.props.contactAvailabilityActions.clearSelectedContacts();
                                        }
                                        this.setState({
                                            filterDropdown: null
                                        });
                                    }}>Apply & Close</button>
                                </div>
                            </div>
                        }
                    </div>

                    { (!window.location.pathname.startsWith("/external")) &&
                        <div className="filter list">
                        <h3>
                            <img src='/icons/users-between-lines-solid.svg' />
                            <span>Groups</span>
                        </h3>
                            <div className="simple-select">
                                <select 
                                    defaultValue={this.props.searchParams.groupId}
                                    onChange={(e) => {
                                        if (e.target.value === "create-new-group") {
                                            history.push("/lists");
                                        } else {
                                            this.handleGroupIdChange(parseInt(e.target.value))
                                        }
                                    }}
                                >
                                    {this.props.groups.map((group, i) => {
                                        return (
                                            <option value={group.id}>
                                                {group.name.replace("EVERYONE", "All contacts")}
                                            </option>
                                        )
                                    }) }
                                    { (!window.location.pathname.startsWith("/external")) &&
                                        <option value={"create-new-group"}>
                                            + Create New Group
                                        </option>
                                    }
                                </select>
                            </div>
                        </div>
                    }

                    { (!window.location.pathname.startsWith("/external")) &&
                        <div className="filter list">
                        <h3>
                            <img src='/icons/calendar-check-solid.svg' />
                            <span>Last Updated Availability</span>
                        </h3>
                            <div className="simple-select">
                                <select 
                                    value={this.props.searchParams.updatedAfter !== null ? ((Utilities.daysBetween(new Date(), new Date(this.props.searchParams.updatedAfter))) + "") : "0"}
                                    onChange={(e) => {
                                        if (e.target.value !== "0") {
                                            const date = Utilities.dateSub(new Date(), "day", parseInt(e.target.value));
                                            this.props.contactAvailabilityActions.changeUpdatedAfter(date.toISOString());
                                        } else {
                                            this.props.contactAvailabilityActions.changeUpdatedAfter(null);
                                        }
                                    }}
                                >
                                    {[
                                        28,
                                        14,
                                        7,
                                        3,
                                        1,
                                        0
                                    ].map((day, i) => {
                                        return (
                                            <option value={day.toString()}>
                                                { (day === 0) ? "Any time" :
                                                    (
                                                        (
                                                            (day === 1) ? "Last 24 hours" :
                                                            (day === 3) ? "Last 3 days" :
                                                            (day === 7) ? "Last 7 days" :
                                                            (day === 14) ? "Last 14 days" :
                                                            (day === 28) ? "Last 28 days" : ""
                                                        ) + " " +
                                                        ("(" + Utilities.formatDate(Utilities.dateSub(new Date(), "day", day), "DD MMM YYYY") + ")")
                                                    )
                                                }
                                            </option>
                                        )
                                    }) }
                                </select>
                            </div>
                        </div>
                    }
                </div>
                }

                { (this.state.selectedThirdPartyLocationSchoolName) &&
                    <div 
                        className='selected-school'
                        style={{
                            background: theme.colours.amber,
                            color: "white",
                            padding: "8px 12px",
                            borderRadius: 5
                        }}
                    >
                        Sorting Distance from: {this.state.selectedThirdPartyLocationSchoolName}
                    </div>
                }

                <button 
                    id="show-more-contacts-btn"
                    onClick={() => {
                        InternalTracker.trackEvent("Availability Paginates", {
                            page: this.props.searchParams.page + 1
                        })
                        this.props.contactAvailabilityActions.changePage(this.props.searchParams.page + 1);
                    }}
                    style={{
                        position: "absolute",
                        left: 0,
                        top: 0,
                        width: 1,
                        height: 1,
                        border: 'none',
                        display: 'none'
                    }}
                >
                </button>

                { (!window.location.pathname.startsWith("/external")) &&
                    <div className="options">
                        <div>
                            {this.props.selectedContacts.length > 0 &&
                            this.props.selectedContacts.length ===
                                this.props.contacts.length ? (
                                <Button
                                    bsStyle="primary"
                                    bsSize="medium"
                                    onClick={this.clearAllContacts}
                                >
                                    <i className="fa fa-minus" /> Clear Selected
                                </Button>
                            ) : (
                                <Button
                                    bsStyle="primary"
                                    bsSize="medium"
                                    onClick={this.selectAllContacts}
                                >
                                    <i className="fas fa-plus-circle" /> Select All
                                </Button>
                            )}
                        </div>
                        <div>
                            <SimpleTooltip
                                id="contact-nudge-tt"
                                text={'Select contacts to nudge for availability by clicking on their availability below'}
                                hide={this.props.selectedContacts.length > 0}
                            >
                                <Button
                                    bsStyle="primary"
                                    bsSize="medium"
                                    onClick={this.nudgeSelected}
                                    disabled={this.props.selectedContacts.length === 0}
                                    data-testid="nudge-selected"
                                >
                                    <i className="fas fa-hand-point-right" /> 
                                    Nudge {this.props.selectedContacts.length > 0 ? (this.props.selectedContacts.length) : ""}
                                </Button>
                            </SimpleTooltip>
                            <SimpleTooltip
                                id="contact-nudge-tt"
                                text={'Select contacts to send offer to by clicking on their availability below'}
                                hide={this.props.selectedContacts.length > 0}
                            >
                                <Button
                                    bsStyle="primary"
                                    bsSize="medium"
                                    onClick={this.offerSelected}
                                    disabled={this.props.selectedContacts.length === 0}
                                >
                                    <i className="fas fa-briefcase-medical" /> 
                                    Send Offer {this.props.selectedContacts.length > 0 ? ("to " + this.props.selectedContacts.length) : ""}
                                </Button>
                            </SimpleTooltip>
                            <Button
                                bsStyle="primary"
                                bsSize="medium"
                                onClick={() => {
                                    this.setState({
                                        pdfTypeSelector: true
                                    })
                                }}
                                className={(window.location.pathname === '/availability/print' ? 'pulse no-size' : '')}
                            >
                                <i className="fas fa-print m-r-xs" />Get PDF
                            </Button>
                            { (this.state.userIsAgency) &&
                                <Button
                                    bsStyle="primary"
                                    bsSize="medium"
                                    style={ this.props.selectedContacts.length > 30 ? { background: theme.colours.red2, borderColor: theme.colours.red2 } : { } }
                                    onClick={() => {
                                        if (this.props.selectedContacts.length > 30) {
                                            toast.error("You can only refer up to 30 contacts at a time")
                                            return;
                                        } else if (!this.props.selectedContacts.length) {
                                            toast.error("You haven't selected anyone")
                                        } else if ((window as any).multiContactReferral) {
                                            (window as any).multiContactReferral(this.props.selectedContacts.map(c => c.contact))
                                        }
                                    }}
                                >
                                    <i
                                        style={{ marginLeft: '5px' }}
                                        className="fas fa-share"
                                    /> 
                                    { (this.props.selectedContacts.length > 30) ?
                                        "Too many contacts selected" :
                                        ("Refer " + (this.props.selectedContacts.length > 0 ? (this.props.selectedContacts.length) : "") + " Contacts")
                                    }
                                </Button>
                            }
                            <Button
                                bsStyle="primary"
                                bsSize="medium"
                                onClick={() => {
                                   const el = document.getElementById("configure-view-btn");
                                   if (el) {
                                       el.click();
                                   }
                                }}
                            >
                                <i className="fas fa-wrench" />Configure View
                            </Button>
                        </div>
                    </div>
                }
                <Modal
                    show={this.state.pdfTypeSelector}
                    onHide={() => { this.setState({ pdfTypeSelector: false }) }}
                >
                    <div
                        style={{
                            padding: 20
                        }}
                    >
                        <h2
                            style={{
                                textAlign: 'center',
                                margin: '0 0 12px 0',
                                fontWeight: 700,
                                fontSize: '1.2em'
                            }}
                        >
                            Select an Option
                        </h2>
                        <button 
                            onClick={this.print.bind(this)}
                            style={{
                                display: 'block',
                                border: 'none',
                                padding: '8px 12px',
                                textAlign: 'center',
                                width: '100%',
                                background: theme.colours.blue2,
                                color: 'white',
                                borderRadius: 5,
                                marginBottom: 12
                            }}
                        >
                            PDF of 5 days by day
                        </button>
                        <button 
                            onClick={this.printV2.bind(this)}
                            style={{
                                display: 'block',
                                border: 'none',
                                padding: '8px 12px',
                                textAlign: 'center',
                                width: '100%',
                                background: theme.colours.blue2,
                                color: 'white',
                                borderRadius: 5
                            }}
                        >
                            PDF of 7 Days by Hours
                        </button>
                    </div>
                </Modal>
            </Wrapper>
        )
    }
}

const Wrapper = styled.div`
    position: relative;

    .loading {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        margin-bottom: 15px;

        i {
            margin-right: 10px;
        }
    }

    .skills {
        position: relative;

        .clear-btn {
            float: right;
            background: ${theme.colours.red2};
            color: white;
            border-radius: 52px;
            font-size: 0.9em;
            padding: 8px 10px;
            border: none;
            line-height: 0.9em;
            position: relative;
            top: -5px;
        }
        
        .sector-selector {
            display: inline-block;
            min-width: 180px;
            position: absolute;
            top: -6px;
            left: 64px;

            &>div>div {
                min-height: unset;
            }
        }

        & > div .suggestion {

        }

        .ReactTags__tagInput {
            background: ${theme.colours.blue2};
        }

        input {
            color: white;
        }

        input::placeholder {
            color: white; opacity: 1;
        }
            
        input:-ms-input-placeholder {
            color: white;
        }
        
        input::-ms-input-placeholder {
            color: white;
        }
    }

    .modal-content {
        padding: 20px;

        h2 {
            text-align: center;
        }

        button {
            padding: 6px 20px;
            text-align: center;
            border: none;
            display: block;
        }
    }

    .filters {
        position: relative;
        margin-top: 10px;

        .filter {
            margin-bottom: 8px;
            border: 1px solid gainsboro;
            padding: 8px;
            border-radius: 16px;
            background: whitesmoke;
            margin-right: 8px;
            width: calc(25% - 8px);
            display: inline-block;
            float: none;

            &:last-child {
                margin-right: 0px;
            }
    
            .simple-select, .simple-input {
                max-width: 100%;
                position: relative;

                select, input {
                    background-color: ${theme.colours.blue2};
                    color: white;
                    width: 100%;
                    border-radius: 6px;
                    padding-left: 4px;
                    padding-right: 4px;
                }

                select {
                    width: 100%;
                }

                input {
                    border: none;
                    outline: none;

                    ::-webkit-input-placeholder { color: white; }
                    :-moz-placeholder { color: white; opacity: 1; }
                    ::-moz-placeholder { color  white; opacity: 1; }
                    :-ms-input-placeholder { color: white; }
                    ::-ms-input-placeholder { color: white; }
                    ::placeholder { color: white; }
                }

                i {
                    position: absolute;
                    top: 0px;
                    right: 0px;
                    font-size: 15px;
                    color: white;
                    cursor: pointer;
                    padding: 3px;
                }
            }
    
            .filter-dropdown {
                position: absolute;
                width: calc(100% - 10px);
                max-height: calc(100vh - 260px);
                left: 5px;
                top: 5px;
                background: white;
                padding: 20px;
                z-index: 10;
                border-radius: 20px;
                overflow: auto;
                box-shadow: ${theme.colours.boxShadow};

                h3 {
                    margin-bottom: 10px;
                }

                .buttons {
                    text-align: right;

                    &>button {
                        border: none;
                        padding: 8px 12px;
                        background: ${theme.colours.blue2};
                        color: white;
                        border-radius: 5px;
                        margin: 10px 0 0 10px;

                        &.reset {
                            background: ${theme.colours.red2};
                        }

                        &:first-child {
                            margin-left: 0;
                        }
                    }
                }

                .locations-list {

                    .location {
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        border-bottom: 1px solid gainsboro;
                        position: relative;
                        padding: 5px;

                        img, .no-img {
                            width: 120px;
                            height: 80px;
                            border-radius: 5px;
                        }

                        label {
                            padding-left: 10px;

                            &.alt {
                                margin-top: 35px !important;

                                span {
                                    margin-left: 6px;
                                }
                            }
                        }

                        &:last-child {
                            border-bottom: none;
                        }

                        &[data-selected="true"] {
                            background: ${theme.colours.blue2};
                            color: white;
                            border-radius: 5px;

                            .no-img {

                                p {
                                    color: white;
                                }
                            }
                        }

                        .org-image {
                            position: absolute;
                            top: -5px;
                            right: -5px;
                            width: 32px !important;
                            height: 32px !important;
                            border-radius: 100%;
                        }
                    }
                }

                // &[data-agency="true"] {
                //     position: fixed;
                //     left: 0;
                //     width: 100%;
                //     height: 100vh;
                //     max-height: unset;
                //     border-radius: 0;
                // }
            }
    
            select {
                border: none;
                background: transparent;
            }
    
            &.sort-by {
    
            }
    
            &.timepreset {
    
                .single-select {
                    margin-bottom: 12px
                }
            }
    
            h3 {
                font-size: 13px;
                line-height: 12px;
                font-weight: 600;
                margin: 0 0 8px 0;
                display: flex;
                align-items: center;
                font-weight: 700;
                justify-content: flex-start;
    
                img {
                    width: 24px;
                    height: 24px;
                    margin-right: 8px;
                }
            }
    
            .single-select {
                // text-align: center;
    
                &>span, &>input {
                    display: inline-block;
                    padding: 4px 12px 6px 12px;
                    border-radius: 52px;
                    background-color: #efefef;
                    color: #333;
                    cursor: pointer;
                    border: 1px solid #d6d6d6;
                    margin-bottom: 10px;
                    
                    &:not(last-child) {
                        margin-right: 12px;
                    }
    
                    &[data-selected="true"] {
                        background-color: ${theme.colours.blue2};
                        color: white;
                        border-color: transparent
                    }
    
                    &>div {
                        width: 220px;
                        margin-left: 12px;
                        margin: -10px 0;
    
                        &>div:first-of-type {
                            background: transparent;
                            border: none;
                        }
    
                        &>div:first-of-type>div>div>div {
                            color: white;
                        }
                    }
                }
    
                &>span {
                    display: inline-flex;
                    align-items: center;
                }
    
                &>input {
                    background-color: ${theme.colours.blue2};
                    font-size: 13px;
                    color: white;
                }
            }
        }
    }

    #daypicker-popover {
        .arrow {
            left: 50% !important;
        }
    }

    strong {
        margin: 0 5px 0 10px;
    }

   .options {
        display: flex;
        justify-content: space-between;

        &>div {

            &:last-child {
                
                button {
                    margin-left: 8px;
                }
            }

            button {
                background-color: ${theme.colours.blue2};
                border: none;
                border-radius: 6px;
                margin-bottom: 8px;
                
                i {
                    margin-right: 8px;
                }
            }
        }
   }

   .no-img {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px;

        p {
            margin: 0;
            text-align: center;
        }
   }

   @media (min-width: 1050px) {
    
        .options {
            position: fixed;
            bottom: 74px;
            left: 250px;
            z-index: 3;
            background: whitesmoke;
            padding: 12px 12px 4px 12px;
            border-radius: 8px;
            box-shadow: ${theme.colours.boxShadow};

                    bottom: 0;
            left: 191px;
            border-radius: 0;
            width: calc(100% - 191px);
        }

        .locations-list {

            .location {
                display: inline-block !important;
                width: 132px;
                margin: 0 10px 10px 0;
                border: none !important;
                background-color: gainsboro;
                border-radius: 5px;
                padding: 5px;

                img, .no-img {
                    width: 100% !important;
                    height: 61px !important;
                }

                .no-img {
                    height: 61px !important;
                }

                label {
                    height: 51px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    font-size: 12px;
                    padding: 0 !important;
                    font-weight: 400;
                    margin: 10px 0 0 0 !important;
                }
            }
        }
   }

//    @media (min-width: 1500px) {
    
//         .options {
//             left: calc(50% + 120px);
//             transform: translate(-50%, 0);
//             bottom: 82px;
//             min-width: 800px;
//         }

//     }

   @media (max-width: 1150px) {

        .filter {

            &:nth-child(1),
            &:nth-child(2),
            &:nth-child(3),
            &:nth-child(4),
            &:nth-child(5),
            &:nth-child(6),
            &:nth-child(7)
            {
                width: calc(33.33% - 6px);
            }

            &:nth-child(3),
            &:nth-child(6)
            {
                margin-right: 0;
            }
        }
   }

   @media (max-width: 900px) {

        .options {
            justify-content: flex-start;
        }
   }

   @media (max-width: 650px) {

        .filter {
            width: 100% !important;
            margin-right: 0px !important;
        }

        .filter-dropdown {
            padding: 10px !important;
        }
   }

   .filters[data-external="true"] {

        .filter {
            width: calc(25% - 6px) !important;

            &:nth-child(3) {
                margin-right: 8px !important;
            }

            @media (max-width: 850px) {
                width: calc(50% - 4px) !important;

                &:nth-child(2) {
                    margin-right: 0px !important;
                }
            }

            @media (max-width: 650px) {
                width: 100% !important;
            }
        }
   }
`;

export default connect(
    (state: ApplicationState) => ({
        searchParams: state.contactAvailability.searchParams,
        timePresets: state.timePresetManagement.timePresetMgtState,
        groups: state.contactManagement.listMgtState.lists,
        contacts: state.contactAvailability.contacts,
        selectedContacts: state.contactAvailability.contactsSelected,
        contactAvailability: state.contactAvailability,
        representedBy: state.representedBy,
    }),
    (dispatch) => ({
        contactAvailabilityActions: bindActionCreators(
            ContactAvailabilityStore.actionCreators,
            dispatch
        ),
        timePresetsActions: bindActionCreators(
            TimePresetsActionCreators.actionCreators,
            dispatch
        ),
        groupActions: bindActionCreators(
            ContactsActionCreators.actionCreators,
            dispatch
        ),
        representedByActions: bindActionCreators(
            RepresentedByActionCreators.actionCreators,
            dispatch
        ),
    })
)(AvailabilityGridToolbar);
