/* eslint-disable */
import AppConfig from './components/config/Config';
import posthog from 'posthog-js'

class InternalTracker {
    static trackEvent(event: string, params?: object, other?: any) {
        if ((window as any).Cypress || localStorage.getItem("disableInternalTracking") || window.location.port === "5001") {
            return null;
        }

        // console.log("@@ TRACKING " + event, params)
        if (window.location.href.indexOf("testing=true") === -1) {
            if ((window as any).posthogTracker) {
                (window as any).posthogTracker.capture(event, params);
            } else {
                // console.log("@@ No tracker, trying again...")
                setTimeout(() => {
                    this.trackEvent(event, params);
                }, 500);
            }
        }
    };

    static init(userOverride?: any) {
        if ((window as any).Cypress || localStorage.getItem("disableInternalTracking") || window.location.port === "5001") {
            return null;
        }
        
        let user = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user") || "") : null;

        if ((window as any).posthogTracker) {
            return;
        }

        if (!user && !userOverride) {
            console.log("@@ No user, and user ovveride not set, trying again...")
            setTimeout(() => {
                this.init();
            }, 250);
            return;
        }

        if (!user && userOverride) {
            user = userOverride;
        }

        if (localStorage.getItem("impersonatedAccessToken")) {
            return;
        }
        
        // openreplayTracker.start()
        // openreplayTracker.setUserID(user.emailAddress);
        // openreplayTracker.setMetadata('email', user.emailAddress);
        // if (user.firstName) {
        //     openreplayTracker.setMetadata('name', user.firstName + " " + user.lastName);
        // }
        // if (user.id) {
        //     openreplayTracker.setMetadata('userId', user.id);
        //     (window as any).globalUserId = user.id
        // }
        // if (user.organisationId) {
        //     openreplayTracker.setMetadata('organisationId', user.organisationId);
        //     openreplayTracker.setMetadata('organisationName', user.organisationName);
        // }

        posthog.init(AppConfig.Settings.posthog.key, { 
            api_host: 'https://eu.i.posthog.com', 
            person_profiles: 'identified_only',
            session_recording: {
                maskAllInputs: false,
                maskInputOptions: {
                    password: true,
                }
            }
        });
    
        posthog.identify(
            user.id,
            {
                email: user.emailAddress,
                name: user.firstName + " " + user.lastName,
                version: user.emailAddress,
                organisationId: user.organisationId ? user.organisationId : null,
                organisationName: user.organisationName ? user.organisationName : null,
            }
        );

        (window as any).posthogTracker = posthog;
    }
}

export default InternalTracker;
