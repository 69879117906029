/* eslint-disable */

interface Api {
    baseUri: string;
    externalUri: string;
    baseStorageUri: string;
    profileImageFolderName: string;
    nodeFunctionsUriSecret: string;
    nodeFunctionsUri: string;
    nodeApiUri: string;
}

interface Authentication {
    redirectUri: string;
    appId: string;
    tenantName: string;
    scope: string;
    signInAndUpPolicyName: string;
    usePortalAuthOnMobilePages: boolean;
}

interface AppInsights {
    enabled: boolean;
    instrumentationKey: string;
}

interface SignalRSection {
    serviceUrl: string;
}

interface StripeSection {
    publicApiKey: string;
}

export interface Configuration {
    api: Api;
    authentication: Authentication;
    appInsights: AppInsights;
    signalR: SignalRSection;
    stripe: StripeSection;
    version: string;
    openReplay: {
        key: string;
    },
    posthog: {
        key: string;
    },
    growthBook: {
        key: string;
        url: string;
    },
    pwaUri: string;
}

let isProd = window.location.hostname.indexOf("portal.updatedge.com") !== -1 && window.location.hostname.indexOf("test-portal.updatedge.com") === -1;
let isLocal = window.location.href.indexOf("localhost:5001") !== -1;

if (localStorage.getItem("env") && localStorage.getItem("env") === "prod") {
    isProd = true
}

export default class AppConfig {

    public static Settings = {
        api: {
            baseUri: /*process.env.REACT_APP_API_BASE_URI ||*/ (isProd ? "https://portal.updatedge.com/legacyapi" : "https://uk-test-uemobileservices.azurewebsites.net"),
            externalUri: isLocal ? "https://localhost:4001" : (isProd ? "https://portal.updatedge.com/api" : "https://uk-test-ueextapi.azurewebsites.net"), // ""
            baseStorageUri: /*process.env.REACT_APP_API_BASE_STORAGE_URI ||*/ (isProd ? "ukliveue.blob.core.windows.net" : "uktestue2.blob.core.windows.net"),
            profileImageFolderName: /*process.env.REACT_APP_API_PROFILE_IMAGE_FOLDER_NAME || */(isProd ? "images/profile" : "images/profile"),
            nodeFunctionsUri: isProd ? "https://func-live-uejs.azurewebsites.net/api" : "https://func-test-uejs.azurewebsites.net/api", // "http://localhost:7071/api"
            nodeApiUri: isProd ? "https://uk-live-uenodejs.azurewebsites.net" : "https://uk-test-uenodejs.azurewebsites.net",
            nodeFunctionsUriSecret: "code=ue",
            imageCdnUri: isProd ? "https://images.updatedge.com" : "https://test-images.updatedge.com",
        },
        authentication: {
            appId: /*process.env.REACT_APP_AUTH_APP_ID ||*/ (isProd ? "c6899b80-ead4-4080-b65b-8d3c0dac1e87" : "0e1749d8-8b6d-4a6f-ac86-9ba4001c5007"),
            redirectUri: `${(window.location.origin ? (window.location.origin + "/redirect") : ( isProd ? "https://portal.updatedge.com/redirect" : "https://test-portal.updatedge.com/redirect" ))}`,
            scope: /*process.env.REACT_APP_AUTH_SCOPE ||*/ (isProd ? "https://upedge.onmicrosoft.com/mobapi/readwrite" : "https://upedgedev.onmicrosoft.com/mobapi/user_impersonation"),
            tenantName: /*process.env.REACT_APP_AUTH_TENANT_NAME ||*/ (isProd ? "upedge" : "upedgedev"),
            signInAndUpPolicyName: "B2C_1A_ENHANCEDSIGNUPORSIGNIN", // /*process.env.REACT_APP_AUTH_POLICY_NAME ||*/ (isProd ? "B2C_1A_MobileSignUpOrSignIn" : "B2C_1A_MobileSignUpOrSignIn"),
            usePortalAuthOnMobilePages: process.env.REACT_APP_AUTH_USE_PORTAL_AUTH_ON_MOBILE_PAGES === 'true' || true
        },
        appInsights: {
            enabled: process.env.REACT_APP_APPINSIGHTS_ENABLED === 'true' || true,
            instrumentationKey: process.env.REACT_APP_APPINSIGHTS_KEY || (isProd ? "00167395-c3cb-419c-ae29-349cf134caea" : "425ee6a9-109f-4d82-86ca-dd1da8fc460a")
        },
        signalR: {
            serviceUrl: process.env.REACT_APP_SIGNALR_SERVICE_URL || (isProd ? "https://uk-live-uesignalr.azurewebsites.net" : "https://uk-test-uesignalr.azurewebsites.net")
        },
        stripe: {
            publicApiKey: process.env.REACT_APP_STRIPE_PUBLIC_KEY || (isProd ? "pk_live_HFeUKtQRFk0goGEuwjFd1bDn00mowy0LnY" : "pk_test_N0Ngh9tezy3xIgewnH7dPbE500ZI4pMSnA"),
        },
        version: process.env.REACT_APP_VERSION || "20240912.3",
        openReplay: {
            key: isProd ? "gncn7t1ngA6wMzom3RdU" : "B6nqekmFpircT53hdHlt"
        },
        posthog: {
            key: isProd ? "phc_q7h5IyMECap8OqH1PjVfILUENfQ1ylXXFC1uKRchFs9" : "phc_G0VEpwM2Wz5WEDRri5cR46QZyElTpO2CbTyvwGfn7yb"
        },
        growthBook: {
            key: isProd ? "sdk-DlRlndgwqZ1cgA0J" : "sdk-VshaHp53jbowQ9ng",
            url: "https://expapi.updatedge.com"
        },
        pwaUri: isProd ? "https://my.updatedge.com" : "https://test-my.updatedge.com",
    };
}
