/* eslint-disable */
import React from 'react';
import styled from 'styled-components';
import { ToastContainer } from 'react-toastify';

import Header from './Header';
import Navigation from './Navigation';
import OverlayMenu from '../overlayMenu/OverlayMenu';
import { Footer } from './Footer';
import 'react-toastify/dist/ReactToastify.css';
import 'animate.css/animate.min.css';
import UECookieConsent from './UECookieConsent';
import theme from '../../css/theme';
import { EventOverlays } from '../ui-components/EventOverlays';

interface State {
    mobileMenuExpanded: boolean;
}

export class Layout extends React.Component<{}, {}> {
    state = {
        mobileMenuExpanded: false
    } as State;

    //private OverlayMenuRef: typeof OverlayMenu | null | undefined;

    public render() {
        return (
            <LayoutContainer
                className={`layout ${
                    this.state.mobileMenuExpanded ? 'expanded' : ''
                }`}
            >
                <ToastContainer position="top-center" />
                <Header
                    onMobileMenuClick={() =>
                        this.setState({ mobileMenuExpanded: true })
                    }
                />
                <Navigation />
                <div id="wrapper">
                    <div className="content">
                        {/*{this.props.children}*/}
                        {React.Children.map(this.props.children, (child) => {
                            if (React.isValidElement(child)) {
                                return React.cloneElement(child, {
                                    // OverlayMenuRef: this.OverlayMenuRef
                                    // TESTPROP: 'PROP'
                                });
                            }
                        })}
                    </div>
                    <OverlayMenu />
                    <Footer />
                </div>
                <EventOverlays />
                <div className="mobile-navigation">
                    <Navigation
                        onNavigate={() =>
                            this.setState({ mobileMenuExpanded: false })
                        }
                    />
                </div>
                <div
                    className="mobile-navigation-mask"
                    onClick={() => this.setState({ mobileMenuExpanded: false })}
                />
                <UECookieConsent />
            </LayoutContainer>
        );
    }
}

const LayoutContainer = styled.div`
    .mobile-navigation,
    .mobile-navigation-mask {
        display: none;
    }

    #header .header-link {
        display: none;
    }

    .profile-head {
        display: flex;
        align-items: center;

        img {
            margin-bottom: 0;
        }

        & > div {
            text-align: left;
            padding-left: 12px;
        }
    }

    @media (min-width: 769px) {
        *[data-desktop-hide="true"] {
            display: none;
        }
    }

    @media (max-width: 768px) {

        #navigation {
            padding-top: 54px;

            #logout-btn {
                position: static !important;
            }
        }

        #header {
            position: static;
            z-index: 1;
            width: 100vw;
            height: 55px;
            background: ${theme.colours.blue2};
            z-index: 4;

            .color-line {
                display: none;
            }

            .header-link {
                display: block;
                margin-right: 0;
                margin-left: 4px;
                padding: 10px;
                font-size: 24px;
                background: ${theme.colours.blue2};
                border: 0;
            }

            .small-logo {

                > img {
                    width: 36px;
                    height: 36px;
                    margin-right: 16px;
                    @media print {
                        display: none !important;
                    }
                }
            }
        }

        #menu {
            display: none;
        }

        #wrapper {
            height: calc(100vh - 55px);
            margin-left: 0;
            overflow-y: scroll;
            padding-bottom: 160px;
        }

        .mobile-menu {
            display: none;
        }

        .profile-head-2 {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 16px 0 0 0;

            &>div:first-child {
                padding: 0 12px 0 0 !important;
            }
        }

        .mobile-navigation {
            position: fixed;
            top: 0;
            left: -190px;
            z-index: 2;
            display: block;
            width: 190px;
            height: 100vh;
            background: white;

            #side-menu {
                height: calc(100vh - 114px);
                overflow-y: auto;
                padding-bottom: 120px;

                &>li[data-hidden="true"] {
                    display: none;
                }
            }
        }

        &.expanded .mobile-navigation {
            left: 0;
            transition: left 0.75s ease;

            #menu {
                top: 0;
                display: block;
            }

            + .mobile-navigation-mask {
                position: fixed;
                top: 0;
                left: 0;
                z-index: 1;
                display: block;
                width: 100vw;
                height: 100vh;
                background: black;
                opacity: 0.75;
            }
        }
    }
`;
