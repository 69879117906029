/* eslint-disable */
import { merge } from 'lodash-es';
import { Reducer, Action, DeepPartial } from 'redux';
import moment, { Moment } from 'moment';
import update from 'immutability-helper';
import { ToastType } from 'react-toastify';
import { UpdateOrganisationRequest } from '../api/organisation/RequestTypes';

import InternalTracker from '../InternalTracker';
import AuthApi from '../components/auth/Auth';
import ProfileApi from '../api/profile/Profile';
import CompanyApi from '../api/company/Company';
import OrganisationApi from '../api/organisation/Organisation';
import { Contact, ServerConfig } from './../store/contacts/Models';
import { AppThunkAction, exhaustiveCheck } from '.';
import * as Notifications from './Notifications';
import {
    UserProfileDto,
    UpdateProfileDto,
    SubscriptionNotificationsDto
} from '../api/profile/RequestTypes';
import { CompanyRoles } from '../api/company/ResponseTypes';
import { SingleResponseDto } from '../api/BaseResponseTypes';
import { Rating } from 'src/api/offers/ResponseTypes';
import { Setting } from 'src/api/settings/ResponseTypes';

// -----------------
// TYPES - Custom types and interfaces
export type AuthProviderType =
    | 'facebook.com'
    | 'twitter.com'
    | 'linkedin'
    | 'updatedge';

// Defines the properties of an access token
export interface UeAccessToken {
    iss: string;
    exp: number;
    nbf: number;
    aud: string;
    oid: string;
    sub: string;
    given_name: string;
    family_name: string;
    extension_SectorId?: number | null;
    extension_ProfileUpdatedAt?: Moment | null;
    extension_ProfileImageUpdatedAt?: Moment | null;
    postalCode: string;
    extension_ParentId: string;
    idp: string;
    name: string;
    emails: string[];
    tfp: string;
    scp: string;
    azp: string;
    ver: string;
    iat: number;
}

export interface UserProfileModel {
    id: string;
    contactId: string;
    firstName: string;
    lastName: string;
    headline?: string;
    displayName: string;
    emailAddress: string;
    authProvider: AuthProviderType;
    sectorId?: number | null;
    organisationId: string | null;
    organisationName: string | null;
    organisationSMSCredit: number | null;
    profileImageUrl: string;
    profileUpdatedAt?: Moment | null;
    profileImageUpdatedAt?: Moment | null;
    loaded: boolean;
    agencyIds: string[];
    betaFeatureOptIn: boolean;
    phoneNumber: string;
    phoneNumberVerified?: boolean;
    role: CompanyRoles;
    userType: number;
    userSetProfileImage: boolean;
    subscriptionNotifications?: SubscriptionNotificationsDto;
    organisationIsAgency?: boolean;
    organisationIsHirer?: boolean;
    ratings: Rating[];
    ratingsGiven: Rating[];
    config: ServerConfig,
    settings: Setting[],
    verified?: boolean;
    homepage?: string;
    awaitingFinalisation?: boolean;
}

// -----------------
// STATE - This defines the type of data maintained in the Redux store.
export interface UserProfileState {
    authenticatedUserProfile: UserProfileModel;
    contactUserProfile: Contact;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.

interface GetAuthenticatedUserProfileAction {
    type: 'GET_AUTH_USER_PROFILE.SUCCESS';
    profile: UserProfileModel;
}
interface GetContactUserProfileAction {
    type: 'GET_CONTACT_USER_PROFILE.SUCCESS';
    contactUserProfile: UserProfileDto;
}
interface UpdateProfileStateAction {
    type: 'UPDATE_PROFILE_STATE';
    state: DeepPartial<UserProfileState>;
}

// Declare a 'discriminated union' type. This lguarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction =
    | GetAuthenticatedUserProfileAction
    | UpdateProfileStateAction
    | GetContactUserProfileAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

let profileLoading: boolean = false;

async function refreshUserProfile(
    dispatch
): Promise<
    | {
          displayName: string;
          contactUserProfile: Contact;
          authenticatedUserProfile: UserProfileModel;
      }
    | SingleResponseDto<UserProfileDto>
> {
    // get the current accces token
    const token = AuthApi.getToken();
    if (token == null || token.accessToken == null)
        return { ...({} as UserProfileState), displayName: 'Not Signed In' };

    // decode and assign to object
    const accessToken: UeAccessToken = AuthApi.getDecodedToken();

    if (profileLoading) {
        // @ts-expect-error
        return;
    }

    profileLoading = true;

    if (localStorage.getItem('user')) {
        const userProfile: UserProfileModel = JSON.parse(localStorage.getItem('user') || "{}");
        dispatch({
            type: 'GET_AUTH_USER_PROFILE.SUCCESS',
            profile: {
                ...userProfile,
                staleData: true
            }
        });
    }

    // call user api to get more details for company
    return ProfileApi.getProfile().then((profile) => {
        // return profile state from access token
        profile.results.contactId
        const userProfile: UserProfileModel = {
            contactId: profile.results.contactId,
            firstName: profile.results.firstName, // can't take from token as may change during session
            lastName: profile.results.lastName, // can't take from token as may change during session
            headline: profile.results.headline || '',
            organisationIsAgency: profile.results.organisationIsAgency,
            organisationIsHirer: profile.results.organisationIsHirer,
            id: profile.results.id || accessToken.oid,
            sectorId:
                accessToken.extension_SectorId != null
                    ? accessToken.extension_SectorId
                    : null,
            profileUpdatedAt:
                accessToken.extension_ProfileUpdatedAt != null
                    ? moment(
                          accessToken.extension_ProfileUpdatedAt,
                          moment.ISO_8601
                      )
                    : null,
            profileImageUpdatedAt: accessToken.extension_ProfileImageUpdatedAt
                ? moment(
                      accessToken.extension_ProfileImageUpdatedAt,
                      moment.ISO_8601
                  )
                : null,
            organisationSMSCredit: profile.results.organisationSMSCredit,
            displayName: accessToken.name,
            emailAddress: profile.hasResults
                ? profile.results.primaryEmail
                : accessToken.emails[0],
            authProvider:
                accessToken.idp === undefined
                    ? 'updatedge'
                    : (accessToken.idp as AuthProviderType),
            profileImageUrl: ProfileApi.getProfileImageUrl(
                profile.results.id || accessToken.oid
            ),
            organisationId: profile.hasResults
                ? profile.results.organisationId
                : '',
            organisationName: profile.hasResults
                ? profile.results.organisationName
                : '',
            loaded: true,
            agencyIds: profile.results.agencyIds || [],
            betaFeatureOptIn: profile.results.betaFeatureOptIn,
            phoneNumber: profile.results.phoneNumber || '',
            phoneNumberVerified: profile.results.phoneNumberVerified,
            role: profile.results.roleId as CompanyRoles,
            userSetProfileImage: profile.results.userSetProfileImage,
            subscriptionNotifications: profile.results.subscriptionNotifications,
            userType: profile.results.userType,
            ratings: profile.results.ratings,
            ratingsGiven: profile.results.ratingsGiven,
            config: profile.results.config,
            settings: profile.results.settings,
            verified: profile.results.verified,
            awaitingFinalisation: profile.results.awaitingFinalisation
        };

        localStorage.setItem('user', JSON.stringify(userProfile));
        InternalTracker.init();

        if (localStorage.getItem("redirectToOnLogin")) {
            window.location.href = localStorage.getItem("redirectToOnLogin") || "/";
            localStorage.removeItem("redirectToOnLogin")
        }

        profileLoading = false;

        const redirectTo = (window as any).onUserGetRedirectTo
        if (redirectTo) {
            (window as any).onUserGetRedirectTo = null;
            setTimeout(() => { 
                if (window.location.href.endsWith(redirectTo)) {
                    window.location.reload();
                } else {
                    window.location.href = redirectTo; 
                }
            }, 250)
        }

        return dispatch({
            type: 'GET_AUTH_USER_PROFILE.SUCCESS',
            profile: userProfile
        });
    });

}

export const actionCreators = {
    updateProfileState: (state): AppThunkAction<UpdateProfileStateAction> => (
        dispatch
    ) => {
        dispatch({
            type: 'UPDATE_PROFILE_STATE',
            state
        });
    },
    getUserProfile: (): AppThunkAction<KnownAction> => (dispatch) => {
        refreshUserProfile(dispatch);
        (window as any).dispatchRefreshUserProfile = () => refreshUserProfile(dispatch);
    },
    getContactUserProfile: () => (
        userId: string
    ): AppThunkAction<KnownAction> => (dispatch, getState) => {
        ProfileApi.getContactUserProfile(userId).then((cup) => {
            dispatch({
                type: 'GET_CONTACT_USER_PROFILE.SUCCESS',
                contactUserProfile: cup
            });
        });
    },
    updateProfile: (
        profileUpdate: UpdateProfileDto
    ): AppThunkAction<KnownAction> => (dispatch, getState) => {
        //debugger;
        ProfileApi.updateProfile(profileUpdate).then(() => {
            Notifications.actionCreators.display(
                ToastType.SUCCESS,
                'Profile updated successfully.'
            );
            refreshUserProfile(dispatch);
            if ((window as any).onUserProfileSave) {
                (window as any).onUserProfileSave();
                (window as any).onUserProfileSave = null;
            }
        });
    },
    updateOrganisation: (
        payload: UpdateOrganisationRequest,
        callback: () => any
    ): AppThunkAction<KnownAction> => (dispatch, getState) => {

        OrganisationApi.updateOrganisation(payload)
            .then(() => {
                Notifications.actionCreators.display(
                    ToastType.SUCCESS,
                    'Organisation updated successfully.'
                );
                refreshUserProfile(dispatch);
            })
            .then(() => callback());
    },
    updateProfileImage: (image: File, userId: string): AppThunkAction<KnownAction> => (
        dispatch,
        getState
    ) => {
        return ProfileApi.updateProfileImage(image, userId).then(() => {
            dispatch({
                type: 'UPDATE_PROFILE_STATE',
                state: {
                    authenticatedUserProfile: {
                        profileImageUpdatedAt: moment()
                    }
                }
            });

            refreshUserProfile(dispatch);
        });
    },
    updateOrganisationProfileImage: (
        image: File,
        callback: () => any
    ) => async (dispatch, getState) => {
        await Promise.resolve()
            .then(() => CompanyApi.updateOrganisationProfileImage(image))
            .then(() => refreshUserProfile(dispatch))
            .then(() => callback());
    },
    updateOrganisationLocation: (
        placeId: google.maps.places.PlaceResult['place_id'],
        fullAddress: string,
        extraDetails: string,
        placeComponents: google.maps.places.PlaceResult['address_components']
    ): AppThunkAction<KnownAction> => (dispatch) => {
        return OrganisationApi.updateLocation(
            placeId,
            fullAddress,
            extraDetails,
            placeComponents
        ).then(() => {
            refreshUserProfile(dispatch);
        });
    }
};

const defaultState = { authenticatedUserProfile: {}, contactUserProfile: {} };

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.
export const reducer: Reducer<UserProfileState | undefined> = (
    state: UserProfileState | undefined,
    incomingAction: Action
) => {
    if (state == undefined) return defaultState as UserProfileState;
    const action = incomingAction as KnownAction;

    switch (action.type) {
        case 'GET_AUTH_USER_PROFILE.SUCCESS':
            return update(state, {
                authenticatedUserProfile: { $set: action.profile }
            });
        case 'GET_CONTACT_USER_PROFILE.SUCCESS':
            return state;
        // return update(state, {
        //     contactUserProfile: { $set: action.contactUserProfile }
        // });
        case 'UPDATE_PROFILE_STATE':
            return merge({}, state, action.state);
        default:
            exhaustiveCheck(action);
    }

    // For unrecognized actions (or in cases where actions have no effect), must return the existing state
    //  (or default initial state if none was supplied)
    return state || (defaultState as UserProfileState);
};
